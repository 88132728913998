export const getNeedToKnowRoute = (pathname) => {
  try {
    if (!pathname || typeof pathname !== "string") {
      return "";
    }
    let needToKnowRoute = pathname.split("/");
    if (needToKnowRoute[2] === "documents") {
      return "/" + needToKnowRoute[1] + "/needtoknow/";
    } else {
      return "/" + needToKnowRoute[1] + "/" + needToKnowRoute[2] + "/needtoknow/";
    }
  } catch (error) {
    return "";
  }
};
