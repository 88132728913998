import { Button, Checkbox, Collapse, Image, Modal, Select, message } from "antd";
import Input from "rc-input";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseCircleOutlined, EyeOutlined, UserAddOutlined } from "@ant-design/icons";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import EmailTemplateModal from "../../Common/components/EmailTemplateModal";
import ContentEditable from "../../Common/components/ContentEditable";
import { PropertyHandlers } from "../state/actions";
import { accountHandle } from "../../Account/state/actions";
import axios from "axios";
import DocumentList from "../../Rtd/component/DocumentList";
import { ClientList } from "../../Client/components/ClientList";
import { documentAction } from "../../DocumentSection/state/actions";
import { generateFullNameFromObj } from "../../Common/utils/extraFunctions";
// import { propertyDataHandle } from "../state/actions";

const { Option } = Select;
const { Panel } = Collapse;

const ReadOnlyModal = ({ selectedData, documentId, role, newEmail, setNewState, currentPropertyDocId, setNestedModalOpen, nestedModalOpen, source, section, newState }) => {
  const delegate =  (section === "DELEGATE");
  const dispatch = useDispatch();
  const [Email_templatevisible, setEmail_templatevisible] = useState(false);
  const [emailSignatureId, setEmailSignatureId] = useState({});
  const [emailArr, setemailarr] = useState([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState([1]);
  const [showClientListModal, setShowClientListModal] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(true);
  const [emailList, setEmailList] = useState({
    emailMessage: "",
  });
  const [selectedtext, setSelectedText] = useState("");
  const { userId } = useSelector((state) => state.account);
  const { emailSignData, emailSignLoading } = useSelector(
    (state) => state.account,
  );
  let { clientsArray, currentlySelectedCorpData, isCorpTrue } = useSelector((state) => state.documentSection.CorpReducer);


  const handleEmailCancel = () => {
    setNestedModalOpen({...nestedModalOpen,isEmailModalVisible:false})
    setEmailList({ emailMessage: ""});
    setemailarr([]);
    setSelectedText(" ");
  };

  const handleInput = (event) => {
    setEmailList({
      ...emailList,
      emailMessage: event.target.innerHTML,
    });
  };

  const handleSelectChange =(id)=>{
    let [filteredValue] = emailSignData?.filter((el)=>el?._id===id).map((el)=>{return {...el, isDefault:true}});
    setEmailSignatureId(filteredValue)
 }

  const handleSend = () => {
    const commonPaload = {
      readOnlyEmailsArray: emailArr,
      ...(emailList.emailMessage ? { emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1) } : {}),
    };

    if (source === "PROPERTY_DOCS") {
      const payloadData = {
        ...commonPaload,
        ...(selectedData?.propertyId ? { propertyId: selectedData.propertyId } : {}),
        ...(selectedData?.offerId ? { offerId: selectedData.offerId } : {}),
        documentId: currentPropertyDocId?.clickedItem?.documentId,
        offerDocument: true,
      };
     dispatch(PropertyHandlers.sharePropertyDocs(payloadData, false));
    } else {
      const roleSpecificPayload = role === "SELLER" ? { sentBy: "SELLER_AGENT", offerId: selectedData?.offerId } : { sentBy: "BUYER_AGENT", offerId: selectedData?._id };

      const payloadData = {
        ...commonPaload,
        documentId,
        ...(selectedData?.persistId ? { ertsId: selectedData.persistId } : {}),
        ...(selectedData?.thirdpartyId?.length > 0 ? { thirdpartyIdDoc: selectedData.thirdpartyId } : {}),
        ...roleSpecificPayload,
        ...(delegate ? ((role === "SELLER") ? {delegateSide: "SELLER"} : {delegateOwnerId: "BUYER"}) : {})
      };
      dispatch(transactionLaneDataHandle.sendEmailToClients(payloadData));
    }
    handleEmailCancel();
  };

  const handleEmailAddress = () => {
    if (isEmailValid(newEmail)) {
      setemailarr((prevEmails) => [newEmail, ...prevEmails]);
      setNewState(prev=>({...prev,email:""}));
    } else {
      // Email is not valid
      message.error("Invalid email address");
    }
  };

  const isEmailValid = (email) => {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailPattern.test(email);
  };

  const handleDeleteEmail = (emailToDelete) => {
    const updatedEmails = emailArr.filter((email) => email !== emailToDelete);
    setemailarr(updatedEmails);
  };
  const handleEmail_templateCancel = () => {
    setEmail_templatevisible(false);
  };

  const handleselectemail = (value) => {
    <div dangerouslySetInnerHTML={{ __html: selectedtext }} />;
    setSelectedText(value);
    setEmailList({
      ...emailList,
      emailMessage: value,
    });
    setEmail_templatevisible(false);
  };

  const handleAddEmail =(email)=>{
    setNewState({...newState, email })
  }

    // when the user select any client in the clientList
    const handleClientSelected = (selectedClient) => {
        if (!selectedClient?.email) {
          message.error("Please add the client's email to proceed!");
          return;
        }
        const clients = clientsArray || [];

        if (clients.length >= 10) {
          message.error(`Maximum number of clients already added.`);
          return;
        }

        const isCorp = currentlySelectedCorpData?.isCorp;
        const existingIndex = clientsArray.findIndex((el) => el.key === currentlySelectedCorpData?.key);

        if (isCorpTrue && existingIndex !== -1) {
          const existingSigners = clientsArray[existingIndex].signers || [];
          if (existingSigners?.some((el) => el.personId === selectedClient._id)) {
            message.error(`Client already exists in the signers list.`);
            return;
          }

          clientsArray[existingIndex].signers = [...(existingSigners || []), { fullName: generateFullNameFromObj(selectedClient), personId: selectedClient._id }];
          dispatch(documentAction.setCorpState({ clientsArray }));
          message.success("Signer added!");
        } else if (!isCorp && clients.some((client) => client.personId === selectedClient._id)) {
          message.error(`Client already exists in the Buyers list.`);
          return;
        } else {
          clientsArray.push({
            fullName: generateFullNameFromObj(selectedClient),
            personId: selectedClient._id,
            isCorp: false,
            signers: [],
          });
          dispatch(documentAction.setCorpState({ clientsArray }));
          message.success("Buyer added!");
        }

        // onChange(selectedClient._id, "searchBuyerType");
      };

  let unmounted = {
    value: false
  };
  let sourceAxios = axios.CancelToken.source();

useEffect(() => {
  dispatch(accountHandle.getEmailSignData({unmounted, source:sourceAxios}));

  return () => {
          unmounted.value = true;
          sourceAxios.cancel("Cancelling in cleanup");
        }
}, []);

useEffect(() => {
    const defaultSignVal = emailSignData?.find(sign => sign.isDefault === true) || { isDefault: false, _id: '' };
    setEmailSignatureId({ ...defaultSignVal });

  return () => {
    setEmailSignatureId({ checked: false, value: '' });
  };
}, [emailSignData]);

  return (
    <>
     <Modal width={'800px'} visible={emailSignatureId?.ispreview} footer={false} onCancel={()=>setEmailSignatureId(prev=>({...prev, isPreview:false}))}>
              {emailSignatureId &&
                  <div
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "10px",
                      margin: "auto",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        <span
                          style={{
                            color: "gray",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Name: {emailSignatureId?.title}
                        </span>
                      </div>
                     </div>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {emailSignatureId?.footerText}
                    </p>

                    <div style={{display:"flex", justifyContent:"center",minHeight:"300px"}}>
                    {emailSignatureId?.signatureImageUrl && (
                      <Image
                        src={emailSignatureId?.signatureImageUrl}
                        alt="Signature"
                        loading="lazy"
                      />
                    )}
                    </div>
                  </div>
                }

      </Modal>

      <Modal
      visible={showClientListModal}
      onCancel={()=>setShowClientListModal(false)}
      footer={false}
      style={{ marginTop: "-80px" }}>
        <ClientList
        listSource={"offerSection"}
         selectClient={handleClientSelected}
        // searchBuyerNameInOffer={input}
        setshowModal={setShowClientListModal}
        page={page}
        setPage={setPage}
        showSearchInput={showSearchInput}
        setShowSearchInput={setShowSearchInput}
        value={value}
        setValue={setValue}
        // clientsArray={clientsArray}
        // isCorpTrue={isCorpTrue}
        // currentlySelectedCorpData={currentlySelectedCorpData}
        // delegateOwnerId={delegateOwnerId}
        />
        <Button
        type="primary"
        style={{bottom:0,left:175,position:"relative",minWidth:"10vw",marginTop:"20px"}}
        onClick={()=>showClientListModal(false)}
        // disabled={isCorpTrue && currentlySelectedCorpData?.signers?.length > 0 ? false : !isCorpTrue && clientsArray?.filter((el) => el.isCorp === false)?.length > 0 ? false : true}
        >Add</Button>
      </Modal>

      <EmailTemplateModal Email_templatevisible={Email_templatevisible} setEmail_templatevisible={setEmail_templatevisible} handleEmail_templateCancel={handleEmail_templateCancel} handleselectemail={handleselectemail} />
      <div>
        <div style={{ marginBottom:"30px"}}>
        <div style={{ width: "700px", textAlign: "center",display:"flex", justifyContent:"center", alignItems:"center" }}>
        <Input
        style={{ width: '50%', height: '32px' }}
        placeholder="Enter email address"
        value={newState.email}
        type="email"
        onChange={(e) => handleAddEmail(e.target.value)}
        onPressEnter={handleEmailAddress}
      />
        <Button type="primary" onClick={handleEmailAddress}>
          Submit
        </Button>
        <Button style={{marginLeft:"20px",}} onClick={()=>setShowClientListModal(true)}>
          <UserAddOutlined style={{fontSize:"22px"}} />
        </Button>
        </div>
        {clientsArray?.length > 0  || emailArr?.length > 0 ? (
          <table className="licenseTable" style={{ marginTop: "20px", fontSize: "18px" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Emails</th>
              </tr>
            </thead>
            <tbody>
              {emailArr.map((ele, i) => (
                <tr>
                  {" "}
                  <td style={{ width: "20%", fontSize: "15px", fontWeight: "bold" }}>{i + 1}</td>
                  <td style={{ width: "80%", fontSize: "15px", fontWeight: "bold" }}>
                    {ele}
                    <CloseCircleOutlined style={{ fontSize: "15px", marginLeft: "5px", color: "gray" }} onClick={() => handleDeleteEmail(ele)} />
                  </td>
                </tr>
              ))}

              {clientsArray.map((ele, i) => (
                <tr>
                  {" "}
                  <td style={{ width: "20%", fontSize: "15px", fontWeight: "bold" }}>{i + 1}</td>
                  <td style={{ width: "80%", fontSize: "15px", fontWeight: "bold" }}>
                    {ele?.fullName}
                    <CloseCircleOutlined style={{ fontSize: "15px", marginLeft: "5px", color: "gray" }} onClick={() => handleDeleteEmail(ele)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ""
        )}
        </div>

    {(nestedModalOpen?.sendMultipleDoc) && (
      <div style={{marginTop:"-20px", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"}}>
             <Collapse ghost accordion>
               <Panel
               header={<span style={{fontSize:"15px"}}>Document List</span>}
                key="1">
                    <div  style={{maxHeight:"200px", overflow:"hidden", overflowY:"auto"}}>
                      <DocumentList selectedData={selectedData} selectedStatus={role} section={section} />
                    </div>
               </Panel>
             </Collapse>
      </div>)}

        <ContentEditable setEmail_templatevisible={setEmail_templatevisible} selectedtext={selectedtext} handleInput={handleInput} emailList={emailList} setEmailList={setEmailList} />

        <div style={{ marginTop: "10px" }}>
        <Checkbox
            checked={emailSignatureId.isDefault}
            onChange={(e) => setEmailSignatureId({ ...emailSignatureId, isDefault: e.target.checked })}
        >
            Include Email Signature
        </Checkbox>
        <Select
            value={emailSignatureId._id} // Use value instead of defaultValue
            style={{ width: 200 }}
            loading={emailSignLoading}
            onChange={(value) => handleSelectChange(value)}
            disabled={!emailSignatureId.isDefault}
        >
            {emailSignData && emailSignData?.length && emailSignData?.map((el, ind) => (
            <Option key={ind} value={el?._id}>{el?.title}</Option>
            ))}
        </Select>
        {emailSignatureId?.isDefault && (<Button style={{fontSize:"16px"}} icon={<EyeOutlined />} onClick={()=>setEmailSignatureId(prev=>({...prev, isPreview:true}))} type="link">Preview</Button>)}
        </div>

        <div style={{ textAlign: "right", marginTop: "10px" }}>
          <Button type="primary" disabled={emailArr?.length <= 0 ? true : false} onClick={handleSend}>
            Send
          </Button>
        </div>
      </div>
    </>
  );
};

export { ReadOnlyModal };
