import { Drawer, Grid } from 'antd';
import React, { useState } from 'react'
import DoraDocument from './DoraDocument';
import { useSelector } from 'react-redux';

const { useBreakpoint } = Grid;

export default function DocsAndVendors({ rtdClient, section }) {
  const [showDrawer, setShowDrawer] = useState(false);
  const isSeller = (rtdClient === "SELLER") ? true : false;
  let { propertyDocs } = useSelector((state) => state.PropertyReducer);
  const screens = useBreakpoint();

  const handleDrawer = () => {
    setShowDrawer(!showDrawer);
  }
  return <>
    {<Drawer
      placement={isSeller ? "left" : "right"}
      open={showDrawer}
      onClose={handleDrawer}
      headerStyle={{ padding: 10 }}
      bodyStyle={{ padding: 10 }}
      title={<div style={{ textAlign: "center", fontWeight: "700", fontFamily: "sans-serif", letterSpacing: "1px", color: "#178DFA" }}>FORMS & AFFILIATES</div>}
    >
      <DoraDocument seller={rtdClient} showDrawer={showDrawer} section={section} propertyDocs={propertyDocs} />
    </Drawer>}

    {!(screens.xl || screens.xxl) &&
      <div className={isSeller ? "docsAndVendorDivMain" : "docsAndVendorBDivMain"}>
        <div onClick={handleDrawer} className={"docsAndVendorDiv"} style={{ color: (isSeller ? "white" : "black"), backgroundColor: (isSeller ? "#2976BE" : "#FFCE51"), [isSeller ? "borderTopRightRadius" : "borderTopLeftRadius"]: "15px", [isSeller ? "borderBottomRightRadius" : "borderBottomLeftRadius"]: "15px" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", lineHeight: "19px" }}>
            <span>F</span>
            <span>O</span>
            <span>R</span>
            <span>M</span>
            <span>S</span>
          </div>
          <div>&</div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", lineHeight: "19px" }}>
            <span>A</span>
            <span>F</span>
            <span>F</span>
            <span>I</span>
            <span>L</span>
            <span>I</span>
            <span>A</span>
            <span>T</span>
            <span>E</span>
            <span>S</span>
          </div>
        </div>
      </div>}
  </>
}
