import { Tabs, PageHeader } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Tasks } from "./Tasks";
import { Calendar } from "./Calendar";
import { encryptStr } from "../../Common/utils/extraFunctions";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircleFilled } from "@ant-design/icons";
import styles from "../styles/tasks.module.css"
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { Loading } from "../../Common";
import { checkIfDisabled, disabledTransactionText } from "../../Common/commondata/commonData";

const TasksAndCalendar = ({section}) => {
  const delegate = (section === "DELEGATE");
  const { pathname } = useLocation();
  const lengthSize = (pathname?.split("/")?.length) - 2
  const activeKey = (pathname?.split("/")[lengthSize] === "sTask") || (pathname?.split("/")[lengthSize] === "bTask") ? "1" : "2";
  const [viewType, setViewType] = useState("month");
  let agentType = ((pathname?.split("/")[lengthSize] === "sTask") || (pathname?.split("/")[lengthSize] === "sCalendar")) ? "SELLER" : "BUYER";
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { getIndividualTransactionData, getIndividualTransactionError } = useSelector(
    (state) => state.rtdTransactionLane,
  );

  let unmounted = {
    value: false,
  };
  let source = axios.CancelToken.source();
  useEffect(()=>{
    let arr = pathname.split("/");
    let offerId = arr.pop();
    offerId = offerId.split("-").join("");

    const delegateSide = delegate && agentType;
    dispatch( transactionLaneDataHandle.getIndividualTransaction({
      offerId,
      unmounted,
      source,
      setLoading,
      delegateSide
    }));

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, [activeKey])

  const isSmall = useMediaQuery({ maxWidth: "651px" });
  const isMobile = useMediaQuery({ maxWidth: "550px" });

  const [state, setState] = useState({
    customModalVisible: false,
    editToggle: false,
    status: false,
    editClickedData: {},
    taskId: "",
  });

  const history = useHistory();
  const location = useLocation();
  const { TabPane } = Tabs;

  const handleBack=()=>{
    history.push({ pathname: pathname?.split("/transactions")?.[0] + "/transactions", state: { category: agentType, categoryId: (agentType === "BUYER") ? getIndividualTransactionData.offerId : getIndividualTransactionData.propertyId}});
  }

  const handleTabClick = (key) => {
    if (key === "1") {
        if(agentType === "BUYER"){
          if(delegate){
            history.push({
              pathname: `/account/delegates/transactions/bTask/${encryptStr(getIndividualTransactionData?.offerId)}`,
            });
          }
          else{
            history.push({
              pathname: `/transactions/bTask/${encryptStr(getIndividualTransactionData?.offerId)}`,
          });
          }
        }else{
          if(delegate){
            history.push({
              pathname: `/account/delegates/transactions/sTask/${encryptStr(getIndividualTransactionData?.offerId)}`,
            });
          }
          else{
            history.push({
              pathname: `/transactions/sTask/${encryptStr(getIndividualTransactionData?.offerId)}`,
            });
          }
        }
    } else if (key === "2") {
        if(agentType === "BUYER"){
          if(delegate){
            history.push(
              `/account/delegates/transactions/bCalendar/${encryptStr(getIndividualTransactionData?.offerId)}`,
            );
          }
          else{
            history.push(
              `/transactions/bCalendar/${encryptStr(getIndividualTransactionData?.offerId)}`,
            );
          }
        }else{
          if(delegate){
            history.push(
              `/account/delegates/transactions/sCalendar/${encryptStr(getIndividualTransactionData?.offerId)}`,
            );
          }
          else{
            history.push(
              `/transactions/sCalendar/${encryptStr(getIndividualTransactionData?.offerId)}`,
            );
          }
        }
     }
  };

  const checkForDisable = checkIfDisabled({openFor: agentType, actualPropertyStatus: getIndividualTransactionData?.actualPropertyStatus, buyingStatus: getIndividualTransactionData?.buyingStatus, offerStatus: getIndividualTransactionData?.status});

  return (
    loading ?
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Loading />
    </div> : getIndividualTransactionError ? "" :
    <>
      <div
        style={isSmall?{
            position: "absolute",
          alignContent: "center",
          alignItems: "center",
          zIndex: "110",
          padding: "0px 20px",
        }:{
          position: "absolute",
          alignContent: "center",
          alignItems: "center",
          zIndex: "110",
          marginTop: "10px",
          padding: "0px 20px"
        }}
      >
        <PageHeader
          style={{ padding: "0", fontSize: "18px", cursor: "pointer" }}
          onBack={() => {handleBack()}}
          title={<span onClick={handleBack} style={isSmall?{fontSize:"15px"}:{}}>Back</span>}
        />
      </div>
      <div>
        <Tabs
          size={"small"}
          centered
          // tabBarStyle={{ outline: "auto", backgroundColor: "yellow"}}
          activeKey={activeKey}
          onTabClick={handleTabClick}
          // style={{ outline: "auto" }}
          tabBarExtraContent={
            <div className={styles.tabContainer}>
              {location.pathname.startsWith("/transactions/bCalendar") ||
              location.pathname.startsWith("/transactions/sCalendar") ? null :
              <div style={{marginRight: "20px"}}>
                <PlusCircleFilled
                  style={
                      isSmall ? {
                      fontSize: "20px",
                      cursor: "pointer",
                      color: "#08518f",
                      margin: "0px 20px",}:
                      {
                      fontSize: "25px",
                      cursor: "pointer",
                      color: "#08518f",
                  }}
                  {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: () => {
                    setState((prevState) => ({
                      ...prevState,
                      customModalVisible: true,
                      editToggle: false,
                      status: false,
                      editClickedData: {},
                    }));
                  }})}
                />
              </div>}
            </div>
          }
        >
          <TabPane
            tab={
              <div style={isSmall?{fontSize:"15px"}:{ marginLeft: "17px", paddingRight: "17px" }}>
                Tasks
              </div>
            }
            key="1"
          >
            <Tasks address={getIndividualTransactionData?.address} state={state} setState={setState} agentType={agentType} section={section}/>
          </TabPane>
              <TabPane tab={<div style={{ fontSize: isSmall ? "15px" : "" }}>Calendar</div>} key="2">
                <Tabs defaultActiveKey="month" size="small"
                  onTabClick={(key)=> (key === "month") ? setViewType("month") : (key === "week") ? setViewType("week"): ""}
                  tabBarStyle={isMobile ? {
                    position: 'absolute',
                    left: '10%',
                    top: -20,
                    fontSize: "10px",
                    zIndex: 1,
                  } : {
                    position: 'absolute',
                    left: '70%',
                    top: -55,
                    zIndex: 1,
                  }}
                  tabBarGutter={15}
                >
                  <TabPane tab={<div style={{ fontSize: isMobile ? 10 : 15 }}>Month</div>} key="month">
                    <Calendar agentType={agentType} section={section} viewType={viewType} />
                  </TabPane>
                  <TabPane tab={<div style={{ fontSize: isMobile ? 10 : 15 }}>Week</div>} key="week">
                    <Calendar agentType={agentType} section={section} viewType={viewType} />
                  </TabPane>
                </Tabs>
              </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default TasksAndCalendar;
