import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import RtdWindow from "./RtdWindow";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";
import { transactionLaneDataHandle } from "../state/actions";
import axios from "axios";
import { message } from "antd";

const RtdDesign = ({ tags, client, section }) => {
  const delegate = (section === "DELEGATE");
  const dispatch = useDispatch();
  const { getTransactionLaneLoading, getIndividualTransactionData, getTransactionLaneError, updateSuccessRtd, getTransactionLaneErrorMsg } = useSelector((state) => state.rtdTransactionLane);

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(()=>{
    const delegateSide = delegate && client;
    dispatch(transactionLaneDataHandle.getTransactionLaneMethod({offerId: getIndividualTransactionData?.offerId, role: client, unmounted, source, delegateSide}));
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [updateSuccessRtd]);

  return (
    <>
      {
        getTransactionLaneLoading ? <div style={{height: "60vh"}}>
          <Loading />
        </div> : (
          getTransactionLaneError ? "" : (
            <div
              style={{
                marginTop: "4%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <RtdWindow
                tags={tags}
                client={client}
                section={section}
              />
            </div>
          )
        )
      }
    </>
  );
};

export default RtdDesign;
