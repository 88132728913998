import axios from "axios";
import dotEnv from "dotenv";
import { filterEmptyAndUndefinedValues } from "../../Common/utils/extraFunctions";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;
const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;

const documentCategoryRequest = () => {
  let accessTokan = localStorage.getItem("userAuth");

  return axios.get(`${apiURL}/categoryListNested`, {
    // params: {
    //   skip: 0,
    //   limit: 50,
    // },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
const searchBuyerRequest = (name) => {
  let accessTokan = localStorage.getItem("userAuth");

  return axios.get(`${apiURL}/customer/search`, {
    params: {
      skip: 0,
      limit: 50,
      search: name || "",
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const createCustomerRequest = ({data, delegateOwnerId}) => {
  const filteredData = filterEmptyAndUndefinedValues(data);
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(
    `${apiURL}/customer/createCustomer`,
    {
      ...{ roleType: "CUSTOMER" },
      ...filteredData,
      ...(delegateOwnerId ? {delegateOwnerId} : {})
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const createRoleRequestApi = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/person/createProfile`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getPropertySearchRequest = (data, demoMode, skip, limit) => {
  let accessTokan = localStorage.getItem("userAuth");
  let destination = data["destination"];
  let streetName = data["street name"];
  let streetNumber = data["street number"];
  // let skip = 0;
  // let limit = 5;

  let queryParameters = "";

  if (streetName) {
    queryParameters += `streetName=${streetName}&`;
  }

  if (streetNumber) {
    queryParameters += `streetNumber=${streetNumber}&`;
  }

  if (data["streetDir"]) {
    queryParameters += `streetDir=${data["streetDir"]}&`;
  }

  if (data["listingId"]) {
    queryParameters += `listingId=${data["listingId"]}&`;
  }

  if (data["unit"]) {
    queryParameters += `unit=${data["unit"]}&`;
  }
  if (data["propertyType"]) {
    queryParameters += `propertyType=${data["propertyType"]}&`;
  }

  let endPoint =
    // destination === "RE Colorado"
    //   ?
    `RETSSearchPropertiesLightening?${queryParameters}skip=${skip}&limit=${limit}&demo=${demoMode}`;
  // : `databaseProperty?search=${streetName}&skip=${skip}&limit=${limit}`;
  return axios.get(`${apiURL}/${endPoint}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// contract offer check api
const contractOfferCheck = async (data) => {
  const accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/contractOfferCheck`, {
    params: data,
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const urlImport = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  if (!data?.clonefromPdf) {
    return axios.post(
      `${apiURL}/urlImport`,
      {
        ...data,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  } else {
    var formData = new FormData();
    formData.append("file", data.file);
    formData.append("clonefromPdf", data.clonefromPdf);
    formData.append("createOffer", data.createOffer);
    formData.append("sellerAgentId", data.sellerAgentId);
    formData.append("propertyId", data.propertyId);
    if(data.delegateSide)formData.append("delegateSide", data.delegateSide);
    
    if (data.transactionData) {
      const transactionDataString = JSON.stringify(data.transactionData);
      formData.append("transactionData", transactionDataString);
    }
    const config = {
      method: "POST",
      url: `${apiURL}/offerImport`,
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
        authorization: `bearer ${accessTokan}`,
      },
      data: formData,
    };
    return axios(config);
  }
};

// URL Shortening API

const buildDocumentUrlShortening = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(
    `${apiURL}/url/builtForSection`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

// preview Contract Offer Api Url

const previewContractApi = (data) => {
  // let accessTokan = localStorage.getItem("userAuth");
  return axios.get(
    `${adminURL}/createOffer/${data._id}`,
    // {
    //   ...data.buyerId
    // },
    {
      headers: {
        accept: "application/json",
        // authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

// drafts api

const draftDocumentsApi = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/offers?status=${data}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const addListingProperty = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(
    `${apiURL}/property/addlisting`,
    { ...data },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const getMLSsearchReq = (data) => {
    let accessTokan = localStorage.getItem("userAuth");
    return axios.post(
      `${apiURL}/getMlsPropertiesToLink`,
      { ...data },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  };

  const getOwnerSearchRequest = (data) => {
    let Tokan = 'QEPhCwPKJD5P3rzKym3miRyHMdbt1cENauj2MZJ7ynhb6hu1y3gaskYpTEDhJQWx';
    return axios.post(
        `${apiURL}/owners`,
        data,
        {
          headers: {
            accept: "application/json",
            authorization: `bearer ${Tokan}`,
          },
        }
      );
};

export const documentDataApi = {
  documentCategoryRequest,
  searchBuyerRequest,
  createCustomerRequest,
  getPropertySearchRequest,
  contractOfferCheck,
  buildDocumentUrlShortening,
  previewContractApi,
  draftDocumentsApi,
  urlImport,
  createRoleRequestApi,
  addListingProperty,
  getMLSsearchReq,
  getOwnerSearchRequest
};
