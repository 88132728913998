import { Modal, Tabs, Tooltip } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import React, { useRef, useState } from "react";
import { EmailModal } from "./EmailModal";
import { ReadOnlyModal } from "./ReadOnlyModal";
import { PlusCircleFilled } from "@ant-design/icons"
import { useDispatch } from "react-redux";
import { PropertyHandlers } from "../state/actions";

const SignableAndReadOnly = ({
      role,
      nestedModalOpen,
      setNestedModalOpen,
      currentPropertyDocId,
      setCurrentPropertyDocId,
      selectedData,
      documentId,
      openfromProperDocs,
      source,
      section,
      agentStatus,
      ispdfClone,
      delegateOwnerId,
      RTDInitiator,
      client,
      contractType,
      reloadSection,
      selectedClient,
      componentUsedSection,
      docSelected,
      setPropertyDocsModalOpen,
      personDocs
     }) => {
  const [newState, setNewState] = useState({email:"",fullName:"",isOpen:false});
  const [ showTextArea,setShowTextArea] = useState(false);
  const [signerChecked, setSignerChecked] = useState({});
  const dispatch=useDispatch()
  const initialMountRef = useRef(false);
  let unmounted = {
    value: false
  };
  const handleEmailCancel = () => {
    setNestedModalOpen({...nestedModalOpen,isEmailModalVisible:false});
    setNewState({email:""});
    setNewState({fullName:""});
    initialMountRef.current = false;
    setSignerChecked({});
    if(source==="PROPERTY_DOCS"){
      const params = {
        ...(selectedData?.offerId ? { offerId: selectedData?.offerId } : {}),
        ...(selectedData?.isProperty || selectedData?.isErts  ? selectedData?.persistId ? { ertsId: selectedData?.persistId } : selectedData?.propertyId   ? { propertyId: selectedData?.propertyId } : {}: {}),
        ...(selectedData?.thirdpartyId ? { thirdpartyIdDoc: selectedData?.thirdpartyId } : {}),
        openFor: selectedData?.isProperty || selectedData?.isErts ? "SELLER_AGENT" : "BUYER_AGENT",
        personId: (selectedData.isProperty || selectedData?.isErts) ? selectedData?.sellerAgentId?._id : selectedData?.buyerAgentId?._id,
        ...(section ? ((selectedData.isProperty || selectedData?.isErts) ? {delegateSide: "SELLER"} : {delegateSide: "BUYER"}) : {}),
        section: "PROPERTY_DOCS"
      };
      dispatch(PropertyHandlers.getPropertyDocsFun({params, unmounted, source}));
      return () => {
        unmounted.value = true;
        source.cancel("Cancelling in cleanup");
      }
    }
    setShowTextArea(false)
  };

  return (
    <>
      {nestedModalOpen?.isEmailModalVisible && (
        <Modal visible={nestedModalOpen?.isEmailModalVisible} onCancel={handleEmailCancel}
        title={<div style={{display: "flex", alignItems: "center", position: "relative"}}>
            <span style={{ fontWeight: "bold", color: "grey",  margin: "0 auto",fontSize:"20px" }}>
                {nestedModalOpen?.sendMultipleDoc ? "SEND EMAIL" : "SEND DOCUMENT TO"}
                {(!nestedModalOpen?.isAgent)&& <Tooltip title="Add Read Only Recepient!" trigger={["hover", "click"]}>
               <PlusCircleFilled size={20} style={{ position: "relative", marginLeft:"10px", marginTop:"5px",fontSize: "25px", color: "#085191",cursor:"pointer"}} onClick={()=>setShowTextArea(true)}/>
               </Tooltip>}
            </span>
        </div>
        }
        style={{marginTop:"-50px"}}
        width={800}
        footer={false}>
              <EmailModal
              role={role}
              section={section}
              source={source}
              client={client}
              contractType={contractType}
              ispdfClone={ispdfClone}
              agentStatus={agentStatus}
              initialMountRef={initialMountRef}
              selectedData={selectedData}
              signerChecked={signerChecked}
              setSignerChecked={setSignerChecked}
              setCurrentPropertyDocId={setCurrentPropertyDocId}
              currentPropertyDocId={docSelected?currentPropertyDocId:{}}
              documentId={docSelected?documentId:""}
              setNestedModalOpen={setNestedModalOpen}
              isEmailModalVisible={nestedModalOpen?.isEmailModalVisible}
              handleEmailCancel={handleEmailCancel}
              nestedModalOpen={nestedModalOpen}
              delegateOwnerId={delegateOwnerId}
              RTDInitiator={RTDInitiator}
              newState={newState}
              setNewState={setNewState}
              reloadSection={reloadSection}
              selectedClient={selectedClient}
              componentUsedSection={componentUsedSection}
              showTextArea={showTextArea}
              setShowTextArea={setShowTextArea}
              setPropertyDocsModalOpen={setPropertyDocsModalOpen}
              personDocs={personDocs}
              />
        </Modal>
      )}
    </>
  );
};

export { SignableAndReadOnly };
