export const appConstants = {
  UPDATE_APP_TITLE: "UPDATE_APP_TITLE",
  NOTIFICATION_SUCCESS: "NOTIFICATION_SUCCESS",
  NOTIFICATION_FAILURE: "NOTIFICATION_FAILURE",
  NOTIFICATION_LOADING:"NOTIFICATION_LOADING",

  UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",
  UPDATE_NOTIFICATION_FAILURE: "UPDATE_NOTIFICATION_FAILURE",

  UPDATE_NOTIFICATION_ISREAD_SUCCESS: "UPDATE_NOTIFICATION_ISREAD_SUCCESS",
  UPDATE_NOTIFICATION_ISREAD_FAILURE: "UPDATE_NOTIFICATION_ISREAD_FAILURE",

  DEVICE_TOKEN_SUCCESS: "DEVICE_TOKEN_SUCCESS",
  DEVICE_TOKEN_FAILURE: "DEVICE_TOKEN_FAILURE",

  DEMO_MODE_SUCCESS: "DEMO_MODE_SUCCESS",

  SET_MANUAL_LOADING: "SET_MANUAL_LOADING",

  NOTIFICATION_COUNT_SUCCESS: "NOTIFICATION_COUNT_SUCCESS",
  NOTIFICATION_COUNT_FAILURE: "NOTIFICATION_COUNT_FAILURE",

  DELETE_NOTIFICATION_COUNT_SUCCESS: "DELETE_NOTIFICATION_COUNT_SUCCESS",
  DELETE_NOTIFICATION_COUNT_FAILURE: "DELETE_NOTIFICATION_COUNT_FAILURE",

  LOCATION_API_SUCCESS: "LOCATION_API_SUCCESS",
  LOCATION_API_FAILURE: "LOCATION_API_FAILURE",

  RETS_DATA_SUCESS: "RETS_DATA_SUCESS",
  RETS_DATA_FAILURE: "RETS_DATA_FAILURE",

  HELP_DATA_SUCCESS: "HELP_DATA_SUCCESS",
  HELP_DATA_ERROR: "HELP_DATA_ERROR",
  HELP_DATA_LOADING: "HELP_DATA_LOADING",

  CLIENT_SCREEN_LOGOUT : "CLIENT_SCREEN_LOGOUT",

  CLIENT_DESK_LOADING: "CLIENT_DESK_LOADING",
  CLIENT_DESK_ERROR: "CLIENT_DESK_ERROR",
  CLIENT_DESK_SUCCESS: "CLIENT_DESK_SUCCESS",

  LOAD_OPACITY_LOADER: "LOAD_OPACITY_LOADER",

  APP_UPDATE_VERSION_SUCCESS : "APP_UPDATE_VERSION_SUCCESS",
  APP_UPDATE_VERSION_LOADING: "APP_UPDATE_VERSION_LOADING",
  APP_UPDATE_VERSION_ERROR: "APP_UPDATE_VERSION_ERROR",
};
