/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { FolderFilled, FolderOpenOutlined, FileZipOutlined, CaretDownOutlined, CaretRightOutlined, SearchOutlined } from "@ant-design/icons";
import { IoIosCreate } from "react-icons/io";
import { MdOutlineUploadFile } from "react-icons/md";
import {  useDispatch, useSelector } from "react-redux";
import {  formatPhoneNumber } from "../../Common/utils/extraFunctions";
import { useLocation } from "react-router-dom";
import { Loading } from "../../Common/components/Loading";
import {  filteredTransactionData, getcombinedClients, removePersonObject } from "./helper";
import { PropertyHandlers } from "../state/actions";
import axios from "axios";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import UploadPdfDoc from "./UploadPdfDoc";
import styles from "../../Rtd/style/document.module.css";
import _ from "lodash";

const StyledTree = styled.div`
  line-height: 1.5;
`;
const StyledFile = styled.div`
  padding-left: px;
  display: flex;
  align-items: center;
  span {
    margin-left: 0px;
  }
`;
const StyledFolder = styled.div`
  padding-left: 0px;
  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 0px;
    }
`;
const Collapsible = styled.div`
  height: ${(p) => (p.isOpen ? "0" : "auto")};
  overflow: hidden;
`;

const File = ({ name, selectedData, seller, propertyDocs, insideFolder, section, handleCreateDocCancel, combinedClientsData }) => {
  const delegate = (section === "DELEGATE");
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const location = useLocation();
  const [isEmailPresent, setIsEmailPresent] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const { propertyData } = useSelector(state=>state.PropertyReducer);
  const item = propertyData?.filter((el) => el?._id === selectedData?._id);
  const [ allData, setAllData ] = useState({
    uploadPdfDoc: false,
    file: "",
    ...(delegate ? ((selectedData.isProperty || selectedData?.isErts) ? {delegateSide: "SELLER"} : {delegateSide: "BUYER"}) : {})
  })

 
  const handleCancel = () => {
    setIsModalVisible(false);
  };
 

  const urlShortHand = (ele) => {
    selectedData = filteredTransactionData({selectedData, ele, source:"PROPERTY_DOCS"});
    let contractId = ele?.contractId?.pop();
    let prefillValues = ele?.prefillValues?.pop() || {};

    let transactionData = selectedData?.newTransactionData || selectedData?.transactionData || {}
    let buildData = {
      builtForId: (seller==="SELLER") ? selectedData?.sellerAgentId?._id : selectedData?.buyerAgentId?._id,
      key: (seller==="SELLER") ? selectedData?.sellerAgentId?._id : selectedData?.buyerAgentId?._id,
      builtForSection: "DOCUMENT",
      signable: true,
      openFor: (seller==="SELLER") ? "SELLER_AGENT" : "BUYER_AGENT",
      RTDInitiator: (seller==="SELLER") ? "SELLER" : "BUYER",
      contractId: (ele.contractType==="SPD" ? "64f97e26494d69c486381500" : ele.contractType==="NTT" && seller==="SELLER"?"65bb626913d7f6bd9231b13b" :ele.contractType==="NTT" && seller==="BUYER"? "65bb61d613d7f6bd9231b13a": contractId),
      contractType: ele?.contractType,
      ...((selectedData?.sellerAgentId?._id) ? {sellerAgentId: selectedData?.sellerAgentId?._id} : {}),
      ...((selectedData?.buyerAgentId?._id) ? {buyerAgentId: selectedData?.buyerAgentId?._id} : {}),
      openForPropertyDoc: (seller==="SELLER") ? "SELLER_AGENT" : "BUYER_AGENT",
      ...(selectedData?.offerId ? { offerId: selectedData?.offerId || "" } : {}),
      token: localStorage.getItem("userAuth"),
      ...(selectedData && selectedData.propertyId ? { propertyId: selectedData.propertyId } : {}),
      ...(selectedData && selectedData.persistId ? { ertsId: selectedData.persistId } : {}),
      ...(selectedData?.thirdpartyId ? { thirdpartyId: selectedData?.thirdpartyId } : {}),
      ...(delegate ? ((seller === "SELLER") ? {delegateOwnerId: selectedData?.sellerAgentId?._id} : {delegateOwnerId: selectedData?.buyerAgentId?._id}) : {}),
      urlData: {
     
       
        ...(ele?.contractType !== "LCAES" && transactionData ? { ...transactionData } : {}),
      
        // Seller Agent details
        SellerBrokerName: selectedData?.sellerAgentId?.fullName || transactionData["SellerBrokerName"] || "",
        SellerBrokerFirmName: selectedData?.sellerAgentId?.brokerageData?.name  || transactionData["SellerBrokerFirmName"] || "",
        SellerBrokerAddress1: selectedData?.sellerAgentId?.brokerageData?.address  || transactionData["SellerBrokerAddress1"] || "",
        SellerBrokerLicenseNumber: selectedData?.sellerAgentId?.licenseId  || transactionData["SellerBrokerLicenseNumber"] || "",
        SellerBrokerPhoneNumber: formatPhoneNumber(selectedData?.sellerAgentId?.mobilePhoneNumber?.phoneNumber) || transactionData["SellerBrokerPhoneNumber"] || "",
        SellerBrokerEmailAddress: selectedData?.sellerAgentId?.email || transactionData["SellerBrokerEmailAddress"] || "",
        SellerBrokerFirmLicenseNumber: selectedData?.sellerAgentId?.brokerageData?.licenseNumber || transactionData["SellerBrokerFirmLicenseNumber"] || "",
        SellerBrokerFaxNumber: formatPhoneNumber(selectedData?.sellerAgentId?.brokerageData?.faxNumber, "FAX") || transactionData["SellerBrokerFaxNumber"] || "",
        
        // Buyer Agent Details
        BuyerBrokerName: (selectedData?.buyerAgentId?.fullName) || transactionData["BuyerBrokerName"] || "",
        BuyerBrokerFirmName: (selectedData?.buyerAgentId?.brokerageData?.name) || transactionData["BuyerBrokerFirmName"] || "",
        BuyerBrokerAddress1: (selectedData?.buyerAgentId?.brokerageData?.address) || transactionData["BuyerBrokerLicenseNumber"] || "",
        BuyerBrokerLicenseNumber: (selectedData?.buyerAgentId?.licenseId) || transactionData["BuyerBrokerLicenseNumber"] || "",
        BuyerBrokerPhoneNumber: (formatPhoneNumber(selectedData?.buyerAgentId?.mobilePhoneNumber?.phoneNumber)) || transactionData["BuyerBrokerPhoneNumber"] || "",
        BuyerBrokerEmailAddress: (selectedData?.buyerAgentId?.email) || transactionData["BuyerBrokerEmailAddress"] || "",
        BuyerBrokerFirmLicenseNumber: selectedData?.buyerAgentId?.brokerageData?.licenseNumber || transactionData["BuyerBrokerFirmLicenseNumber"] || "",
        BuyerBrokerFaxNumber: formatPhoneNumber(selectedData?.buyerAgentId?.brokerageData?.faxNumber,"FAX") || transactionData["BuyerBrokerFaxNumber"] || "",
        FullAddress: selectedData?.address,
        ...(ele?.contractType === "LCAES" ? { LcaeCheckedListingContract: "ERTSListingContract" } : {}),
        ...(ele?.contractType === "LCAES" ? { LcaeInitatingParty: "LcaeInitatingPartySeller" } : {}),
        ...(ele?.contractType === "LCAES" && selectedData?.ertsData ? { ...selectedData.ertsData } : {}),
        ...prefillValues,
        ...((combinedClientsData?.combinedSellerIds?.length > 0) ? {Sellers: removePersonObject(combinedClientsData.combinedSellerIds)} :  {}),
         ...((combinedClientsData?.combinedBuyerIds?.length > 0) ? {Buyers: removePersonObject(combinedClientsData.combinedBuyerIds)} :  {})
      },
    };
    // console.log("buildData", buildData);
    dispatch(transactionLaneDataHandle.doraDocsUrlShorthandApiCalling(buildData, location?.pathname));
  };

  let docInRtd = false;
  propertyDocs?.forEach((el) => {
    if (el.documentName === name.name) {
      docInRtd = true;
    }
  });

  let str = name.name;

  let span1 = "";
  let span2 = "";
  if (str.length > 24) {
    str = str.substr(0, 24);
    var lastSpace = str.lastIndexOf(" ");
    if (!lastSpace) {
      lastSpace = str.lastIndexOf("/");
    }
    if (lastSpace !== -1) {
      span1 = str.substr(0, lastSpace);
      span2 = name.name.substr(lastSpace, name.name.length);
    }
  } else {
    span1 = name.name;
  }

  const handleCreateNew = () => {
    urlShortHand(name);
  };

  const handleUploadNew = () => {
    setAllData({
      ...allData,
      uploadPdfDoc: true,
    })
  }

  const handleModalCancel = () => {
    setAllData({
      ...allData,
      uploadPdfDoc: false
    })
  }


  return (
    <StyledFile style={{}}>
      <span
        style={{
          fontSize: "18px",
          fontWeight: 500,
          textAlign: "left",
          color: docInRtd ? "rgb(110 153 214)" : "#b0b0b0",
          width: "100%",
          marginTop: "4px",
          marginLeft: insideFolder ? "21px" : "auto",
          paddingLeft: 0,
        }}
      >
        <FileZipOutlined style={{ fontSize: "20px", cursor: "pointer", marginRight:"5px", }} />

        <span
          id="fileNameContainer"
        //   className={styles.documentsName}
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (!isEmailPresent) {
              setIsModalVisible(true);
            } else {
              setIsEmailModalVisible(true);
            }
          }}
        >
          {span1}
          {span2}
        </span>
      </span>
      <UploadPdfDoc selectedData={{...selectedData, ...name, RTDInitiator: seller}} allData={allData} setAllData={setAllData} handleModalCancel={handleModalCancel} open={allData?.uploadPdfDoc} successFuncAll={handleCreateDocCancel}/>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
      >
        {selectedData?.propertyYearBuilt > 1977 && (name.contractType === "LBPOS" || name.contractType === "LBPD") && (
          <p style={{ fontWeight: "500", color: "#2976be" }}>
            Note : <text style={{ fontWeight: "500", color: "darkgray" }}>Document Not Required ! This property was built after 1977, exempting the need for this document.</text>{" "}
          </p>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "5px",
            width: "51%",
            margin: "auto",
            paddingLeft: "30px",
          }}
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "20px",
              color: "#2976be",
              cursor: "pointer",
            }}
            onClick={handleCreateNew}
          >
            <IoIosCreate size={25} /> Create New
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "20px",
              color: "#2976be",
              cursor: "pointer",
            }}
            onClick={handleUploadNew}
          >
            <MdOutlineUploadFile size={25} /> Upload PDF
          </p>
        </div>
        {/* )} */}
      </Modal>
    </StyledFile>
  );
};
// its an folder structure component
const Folder = ({ name, children, fiters }) => {
  const [isOpen, setIsOpen] = useState(fiters?.search?.trim() ? true : false);
  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <StyledFolder>
      <div
        className="folder--label"
        onClick={handleToggle}
        style={{
          cursor: "pointer",
        }}
      >
        {isOpen ? (
          <>
          <CaretDownOutlined
            style={{
                fontSize: "1.3rem",
                marginRight: "5px",
                color: "rgb(107,152,199)",
            }}
          />{" "}
            <FolderOpenOutlined style={{ fontSize: "20px", color: "rgb(107,152,199)" }} />
          </>
        ) : (
          <>
            <CaretRightOutlined style={{ fontSize: "1.3rem", color: "#8C8C8C" }} />
            <FolderFilled
              style={{
                fontSize: "20px",
                color: "rgb(107,152,199)"
              }}
            />
          </>
        )}
        <span
          style={{
            fontSize: "19px",
            fontWeight: 600,
            color: "#8C8C8C",
            marginTop: "4px",
            paddingLeft: "4px",
          }}
        >
          {name}
        </span>
      </div>
      <Collapsible isOpen={!isOpen}>{children}</Collapsible>
    </StyledFolder>
  );
};

const Tree = ({ children }) => {
  return <StyledTree>{children}</StyledTree>;
};

Tree.File = File;
Tree.Folder = Folder;

export default function CreateDocs({ seller, item, section, handleCreateDocCancel }) {
  const dispatch = useDispatch();
    const { getPreDoraDocumentsData,getPreDoraDocumentsLoading, getPreDoraDocumentsError, propertyDocs} = useSelector((state) => state.PropertyReducer);
    const [fiters, setFilters] = useState({search: ""});
    const [form] = Form.useForm();
    let unmounted = {
      value: false
    };
    let source = axios.CancelToken.source();
    const handleClear = ({ renderWithClear }) => {
        form.resetFields();
        setFilters({
            search: ""
        })
    }
    useEffect(()=>{
      let data = {
        listType: (seller === "SELLER") ? "SELLER" : "BUYER",
        ...(item?.offerId ? { offerId: item?.offerId }: {}),
      };
      dispatch(PropertyHandlers.getPreDoraDocumentsData({data, unmounted, source, fiters}));

      return () => {
        unmounted.value = true;
        source.cancel("Cancelling in cleanup");
      }
    }, [fiters])

    const combinedClientsData = useMemo(() => {
      const data = getcombinedClients({
        allContractDocuments: propertyDocs || [],
      });
      return {
        combinedBuyerIds: data.combinedBuyerIds,
        combinedSellerIds: data.combinedSellerIds,
      };
    }, [propertyDocs, item, seller]);
  
    //debounce logic to call search api
    const debouncedFunction = _.debounce((value) => {
        setFilters({
            search: value
        })
    }, 1000);

    const handleSearch = (event) => {
        const value = event.target.value;
        debouncedFunction(value);
    };

  return (
    <div style={{ height: "calc(85vh - 8rem)", display: "flex", flexDirection: "column" }}>

      <Row style={{ width: "100%", flexWrap: "nowrap", justifyContent: "center", gap: 5 }}>
        <Col className={styles.searchInputBoxCol}>
          <div className={styles.searchInputBox}>
            <Form form={form} initialValues={{}}>
              <Form.Item name="search">
                <Input
                  allowClear
                  placeholder="Search here..."
                  suffix={<SearchOutlined />}
                  onChange={handleSearch}
                  size="middle"
                />
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col>
          <div
            className={styles.sortBox}
          >
            <Button
              style={{
                background: "#3a74b6",
                color: "whitesmoke",
                fontWeight: "bold",
                border: "none",
                padding: "0 10px"
              }}
              onClick={handleClear}
            >
              <div>Reset</div>
            </Button>
          </div>
        </Col>
      </Row>
      {getPreDoraDocumentsLoading ? (
        <Loading />
      ) : getPreDoraDocumentsError ? "" : (
        <Row span={24} style={{ height: "50vh", overflowY: "auto", marginBottom: "5px" }}>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tree>
              {getPreDoraDocumentsData?.length &&
                getPreDoraDocumentsData.map((ele) => {
                  if (ele.doc_second.length > 0) {
                    return (
                      <Tree.Folder key={ele._id} fiters={fiters} name={ele.name} style={{ cursor: "pointer" }}>
                        {ele.doc_second.map((element) => {
                          return <Tree.File key={element._id} name={element} selectedData={item} seller={seller} propertyDocs={propertyDocs} insideFolder={true} section={section} handleCreateDocCancel={handleCreateDocCancel} combinedClientsData={combinedClientsData} />;
                        })}
                      </Tree.Folder>
                    );
                  } else return <Tree.File key={ele._id} name={ele} selectedData={item} seller={seller} propertyDocs={propertyDocs} insideFolder={false} section={section} handleCreateDocCancel={handleCreateDocCancel} combinedClientsData={combinedClientsData}  />
                })}
            </Tree>
          </Col>
        </Row>
      )}
    </div>
  );
}
