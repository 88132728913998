/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {  Drawer, Dropdown, Grid, Menu, Tooltip, message } from "antd";
import "./style/index.css";
import RtdDesign from "./component/RtdDesign";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { transactionLaneDataHandle } from "./state/actions";
import { CgPrinter } from "react-icons/cg";
import { ArrowLeftOutlined, MoreOutlined, MailOutlined, UsergroupAddOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { encryptStr } from "../Common/utils/extraFunctions";
import { getNeedToKnowRoute } from "./utils/helper";
import { Loading } from "../Common/components/Loading";
import DocsAndVendors from "./component/DocsAndVenders";
import styles from "./style/rtd.module.css";
import axios from "axios";
import { SignableAndReadOnly } from "../PropertyComp/components/SignableAndReadOnly";
import RtdLegend from "../Common/components/RtdLegend";
import DoraDocument from "./component/DoraDocument";
import { PropertyHandlers } from "../PropertyComp/state/actions";
const { useBreakpoint } = Grid;

const TransactionLane = ({ RTDInitiater, section }) => {
  const delegate = (section === "DELEGATE");
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [showlegend, setShowLegend] = useState(false);
  const {  userId } = useSelector((state) => state.account);
  let { propertyDocs } = useSelector((state) => state.PropertyReducer, shallowEqual);
  const [nestedModalOpen, setNestedModalOpen] = useState({
    isEmailModalVisible: false,
    sendMultipleDoc: false,
  });
  const { getIndividualTransactionData, getIndividualTransactionError, addEmailSuccess, getTransactionLaneLoading, getTransactionLaneError, getIndividualTransactionErrorMsg, getTransactionLaneData } = useSelector((state) => state.rtdTransactionLane, shallowEqual);
  let { uploadedPdfDocSuccess } = useSelector((state) => state.PropertyReducer,shallowEqual);
  const rtdClient = (pathname?.split("/")[(pathname?.split("/")?.length) - 2] === "sDashboard") ? "SELLER" : "BUYER";
  const screens = useBreakpoint();

  const handleEmailIconClick = (event) => {
    // event.stopPropagation();
    if (!nestedModalOpen.isEmailModalVisible) {
      setNestedModalOpen({
        ...nestedModalOpen,
        isEmailModalVisible: true,
        sendMultipleDoc: true,
      });
    }
  }

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(()=>{
    let arr = pathname.split("/");
    let offerId = arr.pop();
    offerId = offerId.split("-").join("");

    const delegateSide = delegate && rtdClient;
    dispatch(transactionLaneDataHandle.getIndividualTransaction({offerId, setLoading, unmounted, source, delegateSide}))

    return () => {
      // dispatch(transactionLaneDataHandle.resetRTDData());
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [addEmailSuccess, uploadedPdfDocSuccess])
 
  useEffect(()=>{
  
let source = axios.CancelToken.source();
    let params = {
    ...(getIndividualTransactionData?.offerId ? { offerId: getIndividualTransactionData?.offerId } : {}),
    ...((rtdClient === "SELLER") && (getIndividualTransactionData?.persistId ? { ertsId: getIndividualTransactionData?.persistId }: getIndividualTransactionData?.propertyId ? { propertyId: getIndividualTransactionData?.propertyId } : {})),
    ...((rtdClient === "SELLER") && (getIndividualTransactionData?.thirdpartyId ? { thirdpartyIdDoc: getIndividualTransactionData?.thirdpartyId } : {})),
    openFor: rtdClient === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT",
    personId: rtdClient === "SELLER" ? getIndividualTransactionData?.sellerAgentId?._id : getIndividualTransactionData?.buyerAgentId?._id,
    ...(delegate ? (rtdClient === "SELLER" ? { delegateSide: "SELLER" } : { delegateSide: "BUYER" }) : {}),
  };
if(getIndividualTransactionData?.offerId){
dispatch(PropertyHandlers.getPropertyDocsFun({ params, unmounted, source }));
}
},[getIndividualTransactionData])

  const handlePrintTransRep = (item) => {
    let buildData = {
      builtForId: (rtdClient === "SELLER" ? getIndividualTransactionData?.sellerAgentId?._id : getIndividualTransactionData?.buyerAgentId?._id),
      key: (rtdClient === "SELLER" ? getIndividualTransactionData?.sellerAgentId?._id : getIndividualTransactionData?.buyerAgentId?._id),
      builtForSection: "DOCUMENT",
      signable: true,
      offerId: getIndividualTransactionData.offerId,
      token: localStorage.getItem("userAuth"),
      contractId: '63b6c7f1379c01d3efe69b12',
      contractType: "TR",
      ...(delegate ? {delegateOwnerId: (rtdClient === "SELLER" ? getIndividualTransactionData?.sellerAgentId?._id : getIndividualTransactionData?.buyerAgentId?._id)} : {})
    };
    dispatch(transactionLaneDataHandle.getDraftDocumentData(buildData, pathname, "Print"));
  };

  const openContactSheet = () => {
    let buildData = {
      builtForId: (rtdClient === "SELLER" ? getIndividualTransactionData?.sellerAgentId?._id : getIndividualTransactionData?.buyerAgentId?._id),
      key: (rtdClient === "SELLER" ? getIndividualTransactionData?.sellerAgentId?._id : getIndividualTransactionData?.buyerAgentId?._id),
      builtForSection: "CONTACT_SHEET",
      openFor: (rtdClient === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT"),
      signable: false,
      offerId: getIndividualTransactionData.offerId,
      token: localStorage.getItem("userAuth"),
      ...(delegate ? {delegateOwnerId: (rtdClient === "SELLER" ? getIndividualTransactionData?.sellerAgentId?._id : getIndividualTransactionData?.buyerAgentId?._id)} : {})
    };
    dispatch(transactionLaneDataHandle.getDraftDocumentData(buildData, pathname));
  }

  // Route for need to know
  let needToKnowRoute =  getNeedToKnowRoute(pathname)

  // Menu for sending document or passowrd
  const MenuItem = (item) => {
    return (
      <Menu size="large">
        {getIndividualTransactionData?.status === "SUBMITTED" ||
        getIndividualTransactionData?.status === "TERMINATED" ? null : (
          <Menu.Item
            key="docs"
            onClick={() => {
              history.push({pathname: pathname?.replace("Dashboard", "Dashboard/needToKnow")});
            }}
          >
            <span style={{ color: "04396B" }}>Need to Know</span>
          </Menu.Item>
        )}
        <Menu.Item
          key="contactSheet"
          onClick={() => {
            openContactSheet();
          }}
        >
          <span style={{ color: "04396B" }}>Contact Sheet</span>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <>
    {
      loading ? <Loading /> : (
        getIndividualTransactionError ? "" : <div
          className={(rtdClient === "SELLER") ? styles.laneMainDiv :  styles.laneMainBDiv}
        >
          {
            (rtdClient === "SELLER") && !(getTransactionLaneLoading || getTransactionLaneError) && <DocsAndVendors rtdClient={rtdClient} section={section}/>
          }

          <RtdLegend setShowLegend={setShowLegend} showlegend={showlegend} seller={rtdClient} />

          {
            (screens.xl || screens.xxl) && rtdClient === "SELLER" &&
            <div style={{width: "25%"}}>
              <DoraDocument seller={rtdClient} showDrawer={true} section={section} propertyDocs={propertyDocs}/>
            </div>
          }

          <div className={delegate ? styles.rtdDelegateWidthDiv : styles.rtdWidthDiv}>
            <div className={styles.rtdNavContainer}>
              {/* <div style={{position:"relative",zIndex:"150",marginLeft:"5px", border:"1px solid red"}}>
                <span
                  onClick={() => {
                    history.push({ pathname: pathname?.split("/transactions")?.[0] + "/transactions", state: { category: rtdClient, categoryId: (rtdClient === "BUYER") ? getIndividualTransactionData.offerId : getIndividualTransactionData.propertyId }});
                  }}
                  style={{
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                >
                  <ArrowLeftOutlined style={{ marginRight: "7px" }} />
                  Back
                </span>
              </div> */}

              {/* <div
                style={{
                  fontSize: "20px",
                  display: "flex",
                  justifyItems: "center",
                  justifyContent: "space-around",
                  alignItems: "center",
                  textAlign: "center",
                  position:"relative",
                  zIndex: 150,
                //   border:"1px solid red",
                  width:"90%"
                }}
              > */}
                 <span
                 className={styles.documentName}
                  onClick={() => {
                    history.push({ pathname: pathname?.split("/transactions")?.[0] + "/transactions", state: { category: rtdClient, categoryId: (rtdClient === "BUYER") ? getIndividualTransactionData.offerId : getIndividualTransactionData.propertyId }});
                  }}
                  style={{
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                >
                  <ArrowLeftOutlined style={{ marginRight: "7px" }} />
                  Back
                </span>
                <span className={styles.documentName} onClick={() => setShowLegend(true)} >
                    <InfoCircleOutlined  style={{marginRight: "5px",marginBottom: "-3px",}} />
                    <span className={styles.rtdNavbar}>Information</span>
                </span>
                {(getIndividualTransactionData?.status === "ACCEPTED") && (
                    <span className={styles.documentName} onClick={handlePrintTransRep} style={{marginLeft: "10px",}}>
                      <CgPrinter style={{marginRight: "5px",marginBottom: "-3px",}}/>
                        <span className={styles.rtdNavbar}>Transaction Report</span>
                    </span>
                )}
                <span onClick={openContactSheet} className={styles.documentName}>
                <UsergroupAddOutlined  style={{marginRight: "5px",marginBottom: "-3px",}}/>
                <span className={styles.rtdNavbar}>Contact Sheet</span>
                </span>
                <span onClick={handleEmailIconClick} className={styles.documentName}>
                       <MailOutlined />
                       <span className={styles.rtdNavbar}>{" "}Send Email</span>
                </span>
              {/* </div> */}

            </div>
            <>
            <SignableAndReadOnly
                componentUsedSection="TRANSACTION_DASHBOARD"
                nestedModalOpen={nestedModalOpen}
                setNestedModalOpen={setNestedModalOpen}
                RTDInitiator={rtdClient}
                docSelected={false}
                role={rtdClient}
                source={ rtdClient === "SELLER" ? "SELLING_RTD" : "BUYING_RTD"}
                selectedData={getTransactionLaneData[0]}
                section={section}
                reloadSection="RTD"
                delegateOwnerId={delegate && ((rtdClient === "SELLER") ? getIndividualTransactionData?.sellerAgentId?._id : getIndividualTransactionData?.buyerAgentId?._id)}
              />

              <RtdDesign
                tags={rtdClient}
                client={rtdClient}
                section={section}
              />
            </>
          </div>

          {
            (rtdClient === "BUYER") && !(getTransactionLaneLoading || getTransactionLaneError) && <DocsAndVendors rtdClient={rtdClient} section={section}/>
          }

          {
            (screens.xl || screens.xxl) && rtdClient === "BUYER" &&
            <div style={{width: "25%"}}>
              <DoraDocument seller={rtdClient} showDrawer={true} section={section} propertyDocs={propertyDocs} />
            </div>
          }
        
        </div>
      )
    }
  </>
  );
};

export { TransactionLane };
