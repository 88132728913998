import { Avatar, Badge, List, Tooltip } from "antd";
import React from "react";
import { DeleteTwoTone, UserAddOutlined ,HomeOutlined} from "@ant-design/icons";
import styles from "../style/landingPage.module.css";
import { EmptyData } from "../../Common/components/EmptyData";
import { FaBirthdayCake } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
const ReminderList = ({
  currentReminderData,
  showDeleteConfirm,
  handleListItemClick,
  displayTitle,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 520 });
  const isTab = useMediaQuery({ minWidth:636,maxWidth:1200 });
  const spot1 = useMediaQuery({ minWidth:279,maxWidth:391 });
  const spot2 = useMediaQuery({ minWidth:768,maxWidth:940 });
  const spot3 = useMediaQuery({ minWidth:521,maxWidth:635 });
 return (
    <>
      {currentReminderData?.length > 0 ? (
        <List
          id="list"
          itemLayout="horizontal"
          dataSource={currentReminderData}
          className="reminder"
          style={{overflowX:"hidden"}}
          renderItem={(item) => (
            <List.Item
              style={{
                width: "100%",
                cursor: "pointer",
              }}
              actions={[
                <div style={{ width: "100%" }}>
                  {item.type === "REMINDER" ? (
                    <div>
                      {" "}
                      <DeleteTwoTone
                        style={{
                          color: "black",
                          fontSize: 16,
                        }}
                        onClick={() => showDeleteConfirm(item)}
                      />
                    </div>
                  ) : null}
                </div>,
              ]}
            >
              <List.Item.Meta
                onClick={() => handleListItemClick(item)}
                avatar={
                  <>
                    {item.type === "BIRTHDAY" ? (
                      item?.profileImg?.original ? (
                        <Avatar
                          size={52}
                          src={item?.profileImg?.original}
                        />
                      ) : item?.firstName ? (
                        <Avatar size={52} style={{ background: "#085191" }}>
                          {(item?.firstName
                            ? item?.firstName[0]?.toUpperCase()
                            : "") +
                            "" +
                            (item?.lastName
                              ? item?.lastName[0]?.toUpperCase()
                              : "")}
                        </Avatar>
                      ) : (
                        <Avatar
                          shape="circle"
                          size={52}
                          style={{
                            background: "#085191",
                            // fontSize: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <UserAddOutlined
                            style={{
                              // fontSize: "20px",
                              fill: "#085191",
                              color: "white",
                            }}
                          />
                        </Avatar>
                      )
                    ) : item?.taggedPersonIds?.[0]?.profileImg?.original ? (
                      <Avatar
                        size={52}
                        src={item?.taggedPersonIds?.[0]?.profileImg?.original}
                      />
                    ) : item?.taggedPersonIds?.[0]?.firstName ? (
                      <Avatar size={52} style={{ background: "#085191" }}>
                        {(item?.taggedPersonIds?.[0].firstName
                          ? item?.taggedPersonIds?.[0].firstName[0].toUpperCase()
                          : "") +
                          "" +
                          (item?.taggedPersonIds?.[0].lastName
                            ? item?.taggedPersonIds?.[0].lastName[0].toUpperCase()
                            : "")}
                      </Avatar>
                    ) : (
                      <Avatar
                        shape="circle"
                        size={52}
                        style={{
                          background: "#085191",
                          // fontSize: "24px",
                          display: "flex",
                          alignItems: "center",
                          // height: "52px",
                        }}
                      >
                        <UserAddOutlined
                          style={{
                            // fontSize: "24px",
                            fill: "#085191",
                            color: "white",
                          }}
                        />
                      </Avatar>
                    )}
                  </>
                }
                title={
                  <Tooltip placement="top" title={displayTitle(item, true)}>
                    <p
                      style={{
                        fontSize: "15px",
                        width: spot1 ? "170px" : isMobile ? "220px" : isTab ? "600px" : "130%",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {displayTitle(item)}
                    </p>
                  </Tooltip>
                }
                description={
                  <div
                    style={{
                      marginTop: "-5px",
                      display: "flex",
                      alignItems: "center",
                      gap:"10px"
                     
                    }}
                  >
                    {item.type === "BIRTHDAY" ? (
                    <>
                      <div>
                        <FaBirthdayCake style={{ fill:"#000000"}}/>
                      </div>
                      <div
                        style={{
                          marginTop: "-30px",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "90px",
                        }}
                      >
                        <Badge
                          count={"Birthday"}
                          style={{ marginBottom:"7px" ,color:"black",background:"lightgreen"}}
                        />{" "}
                      </div>
                    </>
                   ) :item?.type === "CONTRACT_REMINDER"? (
                    <>
                    <div>
                    <HomeOutlined  style={{ fill:"#000000",marginBottom:"20px"}}/>
                      </div>
                      <div
                       style={{maxWidth:"150px"}}
                      >
                      <p style={{
                           color:"#333333",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width:spot3 ? "250px" : spot2 ? "300px" : spot1 ? "150px" : isMobile ? "200px" : isTab ? "450px" : "130%",
                           }}>{item?.fullAddress}</p>
                        
                      </div>
                      </>) : item?.type === "REMINDER" ? (
                        <>
                          <div
                            style={{ maxWidth: "150px" }}
                          >
                            <p style={{
                              color: "#333333",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: spot3 ? "250px" : spot2 ? "300px" : spot1 ? "150px" : isMobile ? "200px" : isTab ? "450px" : "130%",
                            }}>{item?.description}</p>

                          </div>
                        </>
                      ) : ("")} 
                  </div>
                }
              />
            </List.Item>
          )}
        />
      ) : (
        <div style={{ marginTop: "63%" }}>
          <EmptyData />
        </div>
      )}
    </>
  );
};

export default ReminderList;
