import { clientConstants } from "./action-types";
import { clientDataApi } from "../utils/api";

import dotEnv from "dotenv";
import { message } from "antd";
import { accountHandle } from "../../Account/state/actions";
import { landingPageAction } from "../../Landing/state/actions";
import { v4 as uuidv4 } from "uuid";


dotEnv.config();

const apiURL = process.env.REACT_APP_ADMIN_BASE_URL;
/**
 * All Actions for Client
 */

/**
 * To update Category state
 * @param {string} auth
 */
//handling the files under the transaction tab
const handleDraftOpen = () => {
  return {
    type: clientConstants.DRAFT_TOGGLE,
  };
};
const handleCurrentOpen = () => {
  return {
    type: clientConstants.CURRENT_TOGGLE,
  };
};
const handlePastOpen = () => {
  return {
    type: clientConstants.PAST_TOGGLE,
  };
};

//Get getClientRequest
const getClientLoading = () => {
  return {
    type: clientConstants.CLIENT_LIST_LOADING,
  };
};
const getClientError = (error) => {
  return {
    type: clientConstants.CLIENT_LIST_FAILURE,
    getClientError: error,
  };
};

const getClientSuccess = (response) => {
  return {
    type: clientConstants.CLIENT_LIST_SUCCESS,
    totalCount: response?.clients?.[0]?.totalItems,
    getClientData: response?.clients?.[0]?.data || [],
    activeClients : response?.ActiveClients?.[0]?.data || []
  };
};

const clearGetClientData = () => {
  return {
    type: clientConstants.CLIENT_LIST_CLEAR
  };
}

const getClientList = ({value, section, page, limit, selectedSortBy, selectedSortOrder, selectedRoles, personId, setSelectedClient, delegateOwnerId}) => (dispatch) => {
  dispatch(getClientLoading());
  return clientDataApi
    .getClientRequest({value, section, page, limit, selectedSortBy, selectedSortOrder, selectedRoles, personId, delegateOwnerId})
    .then((response) => {
      if (page === 1 && setSelectedClient) {
        dispatch(clientAction.clearGetClientData());
        setSelectedClient("")
      }
      const newData = response?.data?.info;
      dispatch(getClientSuccess(newData));
    })
    .catch((error) => {
      dispatch(getClientError(error));
    });
};

//Get person details
const personDetailsLoading = () => {
  return {
    type: clientConstants.CLIENT_DETAILS_LOADING,
  };
};
const personDetailsError = (error) => {
  return {
    type: clientConstants.CLIENT_DETAILS_FAILURE,
    personDetailsError: error,
  };
};

const personDetailsSuccess = (response) => {
  return {
    type: clientConstants.CLIENT_DETAILS_SUCCESS,
    personDetails: response.data.info,
  };
};

const getPerson = ({clientId, source, unmounted}) => (dispatch) => {
  dispatch(personDetailsLoading());
  return clientDataApi
    .getPersonDetailsRequest({clientId, source})
    .then((response) => {
      if(!unmounted.value){
        dispatch(personDetailsSuccess(response));
      }
    })
    .catch((error) => {
      if(!unmounted.value){
        message.error(error?.response?.data?.message || "We encountered an issue while getting person details. Please try again later!");
        dispatch(personDetailsError(error));
      }
    });
};

//Get Person Docs
const getPersonDocsLoading = () => {
  return {
    type: clientConstants.PERSON_DOCS_LOADING,
  };
};
const getPersonDocsError = (error) => {
  return {
    type: clientConstants.PERSON_DOCS_FAILURE,
    personDocsError: error,
  };
};

const getPersonDocsSuccess = (response) => {
  return {
    type: clientConstants.PERSON_DOCS_SUCCESS,
    personDocs: response.data.info,
  };
};

const getPersonDocs = ({id, unmounted, source}) => (dispatch) => {
  dispatch(getPersonDocsLoading());
  return clientDataApi
    .getPersonDocsRequest({id, source})
    .then((response) => {
      if(!unmounted.value){
        dispatch(getPersonDocsSuccess(response));
      }
    })
    .catch((error) => {
      if(!unmounted.value){
        message.error(error?.response?.data?.message || "We encountered an issue while getting person documents. Please try again later!", 2);
        dispatch(getPersonDocsError(error));
      }
    });
};

//Get memories
const getMemoriesloading = () => {
  return {
    type: clientConstants.MEMORY_DETAILS_LOADING,
  };
};
const getMemoriesError = (error) => {
  return {
    type: clientConstants.MEMORY_DETAILS_FAILURE,
    memoriesError: error,
  };
};
const getMemoriesSuccess = (response) => {
  return {
    type: clientConstants.MEMORY_DETAILS_SUCCESS,
    memories: response.data.info,
  };
};

const getMemories = ({id, unmounted, source}) => (dispatch) => {
  dispatch(getMemoriesloading());
  return clientDataApi
    .getMemoriesDetailsRequest({id, source})
    .then((response) => {
      if(!unmounted.value){
        dispatch(getMemoriesSuccess(response));
      }
    })
    .catch((error) => {
      if(!unmounted.value){
        dispatch(getMemoriesError(error));
      }
    });
};

//delete images inside photos
const deleteMemoriesLoading = () => {
  return {
    type: clientConstants.DELETE_MEMORIES_LOADING,
  };
};
//delete images inside photos
const deleteMemoriesError = (error) => {
  return {
    type: clientConstants.DELETE_MEMORIES_FAILURE,
    deleteMemoriesError: error,
  };
};

const deleteMemoriesSuccess = (response) => {
  return {
    type: clientConstants.DELETE_MEMORIES_SUCCESS,
    deleteMemoriesData: response.data.info,
  };
};

const deleteMemories = (id, clientId, message) => (dispatch) => {
  dispatch(getMemoriesloading());
  return clientDataApi
    .deleteMemoriesRequest(id)
    .then((response) => {
      message.success("Deleted Successfully!", 2);
      dispatch(postImageSuccess(response));
      dispatch(renderImageSuccess());
    })
    .catch((error) => {
      message.error(error?.response?.data?.message || "We encountered an issue while deleting the memories. Please try again later!", 2);
      dispatch(postImageError(error));
    });
};

// NOTES UPDATE INSIDE MEMORIES
const updateNoteLoading = () => {
  return {
    type: clientConstants.UPDATE_MEMORY_LOADING,
  };
};
// const updateNoteFailure = (error) => {
//   return {
//     type: clientConstants.UPDATE_MEMORY_FAILURE,
//     updateNotesError: error,
//   };
// };

// const updateNoteSuccess = (response) => {
//   return {
//     type: clientConstants.UPDATE_MEMORY_SUCCESS,
//     updateNotesDetails: response.data.info,
//   };
// };

const updateNoteApi = (payload, clientId, message) => (dispatch) => {
  dispatch(getMemoriesloading());
  return clientDataApi
    .updateNotesApi(payload)
    .then((response) => {
      message.success("Notes Updated Successfully!", 2);
      dispatch(postImageSuccess(response));
      dispatch(renderImageSuccess());
    })
    .catch((error) => {
      message.error(error.response.data.message);
      dispatch(postImageError(error));
    });
};

//Get current client details
const currentClientLoading = () => {
  return {
    type: clientConstants.CURRENT_CLIENTS_LOADING,
  };
};
const currentClientError = (error) => {
  return {
    type: clientConstants.CURRENT_CLIENTS_FAILURE,
    currentCLientError: error,
  };
};

const currentClientSuccess = (response) => {
  return {
    type: clientConstants.CURRENT_CLIENTS_SUCCESS,
    currentCLientDetails: response,
  };
};

const getCurrentClient = ({customerId, statusType, source, unmounted}) => (dispatch) => {
  dispatch(currentClientLoading());
  return clientDataApi
    .getCurrentClientRequest({customerId, statusType, source})
    .then((response) => {
      if(!unmounted.value){
        dispatch(currentClientSuccess(response.data.info));
      }
    })
    .catch((error) => {
      if(!unmounted.value){
        dispatch(currentClientError(error));
      }
    });
};

//Get documentry List
const getDocumentryDetailsLoading = () => {
  return {
    type: clientConstants.DOCUMENTRY_LIST_LOADING,
    documentryListLoading: true,
  };
};
const getDocumentryDetailsError = (error) => {
  return {
    type: clientConstants.DOCUMENTRY_LIST_FAILURE,
    documentryListError: error,
    documentryListLoading: false,
  };
};

const getDocumentryDetailsSuccess = (response) => {
  return {
    type: clientConstants.DOCUMENTRY_LIST_SUCCESS,
    documentryListDetails: response.data.info,
    documentryListLoading: false,
  };
};

const getDocumentryDetails = (listType, offerId) => (dispatch) => {
  dispatch(getDocumentryDetailsLoading(true));
  return clientDataApi
    .getDocumentryListRequest(listType, offerId)
    .then((response) => {
      dispatch(getDocumentryDetailsSuccess(response));
    })
    .catch((error) => {
      dispatch(getDocumentryDetailsError(error));
    });
};

//Get past client details
const pastCLientLoading = (error) => {
  return {
    type: clientConstants.PAST_CLIENTS_LOADING,
  };
};
const pastClientError = (error) => {
  return {
    type: clientConstants.PAST_CLIENTS_FAILURE,
    pastCLientError: error,
  };
};

const pastClientSuccess = (response) => {
  return {
    type: clientConstants.PAST_CLIENTS_SUCCESS,
    pastCLientDetails: response.data.info,
  };
};

const getPastClient = ({customerId, statusType, source, unmounted}) => (dispatch) => {
  dispatch(pastCLientLoading());
  return clientDataApi
    .getCurrentClientRequest({statusType, customerId, source})
    .then((response) => {
      if(!unmounted.value){
        dispatch(pastClientSuccess(response));
      }
    })
    .catch((error) => {
      if(!unmounted.value){
        dispatch(pastClientError(error));
      }
    });
};

//Post event Data
const postEventError = (error) => {
  return {
    type: clientConstants.POST_EVENT_FAILURE,
    postEventError: error,
    postEventLoading: false,
  };
};

const postEventSuccess = (response) => {
  return {
    type: clientConstants.POST_EVENT_SUCCESS,
    postEventDetails: response.data.info,
    postEventLoading: false,
  };
};

const postEvent = (data, id) => {
  return (dispatch, getState) => {
    clientDataApi
      .postEventRequest(data, id)
      .then((response) => {
        dispatch(postEventSuccess(response));
        dispatch(renderPersonDetails());
        message.success("Updated Successfully!", 2);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while posting the event. Please try again later!"
        );
        dispatch(postEventError(error));
      });
  };
};

//Edit event Data
const editEventEventError = (error) => {
  return {
    type: clientConstants.EDIT_EVENT_FAILURE,
    editEventError: error,
    editEventLoading: false,
  };
};

const editEventSuccess = (response) => {
  return {
    type: clientConstants.EDIT_EVENT_SUCCESS,
    editEventDetails: response.data.info,
    editEventLoading: false,
  };
};

const editEvent = (data, event) => {
  return (dispatch, getState) => {
    clientDataApi
      .editEventRequest(data, event)
      .then((response) => {
        dispatch(editEventSuccess(response));
        dispatch(renderPersonDetails());
        message.success("Updated Successfully!", 2);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while editing event. Please try again later!"
        );
        dispatch(editEventEventError(error));
      });
  };
};

//Edit name Data
const updateNameError = (error) => {
  return {
    type: clientConstants.EDIT_NAME_FAILURE,
    updateNameError: error,
    updateNameLoading: false,
  };
};

const updateNameSuccess = (response) => {
  return {
    type: clientConstants.EDIT_NAME_SUCCESS,
    updateNameDetails: response.data.info,
    updateNameLoading: false,
  };
};

const updateName = (data, event, setPage, setSelectedClient) => {
  return (dispatch, getState) => {
    clientDataApi
      .updateNameRequest(data, event)
      .then((response) => {
        dispatch(updateNameSuccess(response));
        clientCall(data, setPage, setSelectedClient, dispatch);
        message.success("Updated Successfully!", 2);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while updating the name. Please try again later!"
        );
        dispatch(updateNameError(error));
      });
  };
};

const clientCall = (data, setPage, setSelectedClient, dispatch, listSource) => {
  if(listSource === "offerSection" || listSource === "calendar"){
    dispatch(clearGetClientData());
    setPage([1]);
  }else if(setPage && setSelectedClient && dispatch){
    dispatch(clearGetClientData());
    setSelectedClient("");
    setPage([1]);
  }
}

const updateRoleHashtags  = (data, personId) => {
  return (dispatch, getState) => {
    clientDataApi
      .updateroleHashtagRequest(data, personId)
      .then((response) => {
        dispatch(renderPersonDetails());
        message.success("Updated Successfully!", 2);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while updating role hashtags. Please try again later!"
        );
        dispatch(updateNameError(error));
      });
  };
};

//update Address
const updateAddressError = (error) => {
  return {
    type: clientConstants.UPDATE_ADDRESS_FAILURE,
    updateAddressError: error,
    updateAddressLoading: false,
  };
};

const updateAddressSuccess = (response) => {
  return {
    type: clientConstants.UPDATE_ADDRESS_SUCCESS,
    updateAddressDetails: response.data.info,
    updateAddressLoading: false,
  };
};

const updateAddress = (values, data, personId) => {
  return (dispatch, getState) => {
    clientDataApi
      .updateAddressRequest(values, data, personId)
      .then((response) => {
        dispatch(updateAddressSuccess(response));
        dispatch(renderPersonDetails());
        message.success("Updated Successfully!", 2);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while updating the address. Please try again later!"
        );
        dispatch(updateAddressError(error));
      });
  };
};
//update && post phone
const updatePhoneError = (error) => {
  return {
    type: clientConstants.UPDATE_PHONE_FAILURE,
    updatePhoneError: error,
    updatePhoneLoading: false,
  };
};

const updatePhoneSuccess = (response) => {
  return {
    type: clientConstants.UPDATE_PHONE_SUCCESS,
    updatePhoneDetails: response.data.info,
    updatePhoneLoading: false,
  };
};

const renderPersonDetails = (response) => {
  return {
    type: clientConstants.RENDER_PERSON_DETAIL
  };
};

const updatePhone = (values, data, personId, section, source) => {
  return (dispatch, getState) => {
    clientDataApi
      .updatePhoneRequest(values, data, personId, source)
      .then((response) => {
        dispatch(updatePhoneSuccess(response));
        if (section === "CLIENT") {
          dispatch(renderPersonDetails());
        } else if (section === "ACCOUNT") {
          dispatch(accountHandle.getUserDetails(personId));
        }
        message.success("Updated Successfully!", 2);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while updating the phone details. Please try again later!"
        );
        dispatch(updatePhoneError(error));
      });
  };
};

//update && Email phone
const updateEmailError = (error) => {
  return {
    type: clientConstants.UPDATE_EMAIL_FAILURE,
    updateEmailError: error,
    updateEmailLoading: false,
  };
};

const updateEmailSuccess = (response) => {
  return {
    type: clientConstants.UPDATE_EMAIL_SUCCESS,
    updateEmailDetails: response.data.info,
    updateEmailLoading: false,
  };
};

const updateEmail = (values, data, personId, setPage, setSelectedClient,listSource, setPersonId,offerId) => {
  return (dispatch, getState) => {
    clientDataApi
      .updateEmailRequest(values, data, personId)
      .then((response) => {
        dispatch(updateEmailSuccess(response));
        clientCall(values, setPage, setSelectedClient, dispatch, listSource);
        message.success("Updated Successfully!", 2);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while updating the email. Please try again later!"
        );
        dispatch(updateEmailError(error));
      });
  };
};

//update && post Title
const updateTitleError = (error) => {
  return {
    type: clientConstants.UPDATE_TITLE_FAILURE,
    updateTitleError: error,
    updateTitleLoading: false,
  };
};

const updateTitleSuccess = (response) => {
  return {
    type: clientConstants.UPDATE_TITLE_SUCCESS,
    updateTitleDetails: response.data.info,
    updateTitleLoading: false,
  };
};

const updateTitle = (values, data, personId) => {
  return (dispatch, getState) => {
    clientDataApi
      .updateTitleRequest(values, data, personId)
      .then((response) => {
        dispatch(updateTitleSuccess(response));
        dispatch(renderPersonDetails());
        message.success("Updated Successfully!", 2);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while updating the title. Please try again later!"
        );
        dispatch(updateTitleError(error));
      });
  };
};

//update && post Title
const updateNotesError = (error) => {
  return {
    type: clientConstants.UPDATE_NOTES_FAILURE,
    updateNotesError: error,
    updateNotesLoading: false,
  };
};

const updateNotesSuccess = (response) => {
  return {
    type: clientConstants.UPDATE_NOTES_SUCCESS,
    updateNotesDetails: response.data.info,
    updateNotesLoading: false,
  };
};

const updateNotesLoading = () => {
  return {
    type: clientConstants.UPDATE_NOTES_LOADING,
    updateNotesLoading:true,
  }
}

const updateNotes = (values, data, personId,urlFor) => {
  return (dispatch) => {
    dispatch(updateNotesLoading())
    clientDataApi
      .updateNotesRequest(values, data, personId)
      .then((response) => {
        dispatch(updateNotesSuccess(response));
        dispatch(renderPersonDetails());
        if(urlFor === "imageUpload"){
          message.success("Image uploaded and notes updated successfully!", 2);
        }else{
          message.success("Updated Successfully!", 2);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while updating the notes. Please try again later!"
        );
        dispatch(updateNotesError(error));
      });
  };
};

//update && post BirthDay
const updateBirthdayError = (error) => {
  return {
    type: clientConstants.UPDATE_BIRTHDAY_FAILURE,
    updateBirthdayError: error,
    updateBirthdayLoading: false,
  };
};

const updateBirthdaySuccess = (response) => {
  return {
    type: clientConstants.UPDATE_BIRTHDAY_SUCCESS,
    updateBirthdayDetails: response.data.info,
    updateBirthdayLoading: false,
  };
};

const updateBirthday = (values, data, personId) => {
  return (dispatch, getState) => {
    clientDataApi
      .updateBirthdayRequest(values, data, personId)
      .then((response) => {
        dispatch(updateBirthdaySuccess(response));
        dispatch(renderPersonDetails());
        message.success("Updated Successfully!", 2);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while updating the birthday. Please try again later!"
        );
        dispatch(updateBirthdayError(error));
      });
  };
};

//delete event Data
const deleteEventError = (error) => {
  return {
    type: clientConstants.DELETE_EVENT_FAILURE,
    deleteEventError: error,
    deleteEventLoading: false,
  };
};

const deleteEventSuccess = (response) => {
  return {
    type: clientConstants.DELETE_EVENT_SUCCESS,
    deleteEventDetails: response.data.info,
    deleteEventLoading: false,
  };
};

const deleteEvent = (id, personId) => {
  return (dispatch, getState) => {
    clientDataApi
      .deleteEventRequest(id)
      .then((response) => {
        dispatch(deleteEventSuccess(response));
        dispatch(renderPersonDetails());
        message.success("deteted Successfully!", 2);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while deleting the event. Please try again later!"
        );
        dispatch(deleteEventError(error));
      });
  };
};

// Post image Url
const postImageError = (error) => {
  return {
    type: clientConstants.POST_IMAGE_FAILURE,
    postImageError: error,
    postImageLoading: true,
  };
};

const postImageSuccess = (response) => {
  return {
    type: clientConstants.POST_IMAGE_SUCCESS,
    postImageData: response.data.info,
    postImageLoading: false,
  };
};

const renderImageSuccess = () => {
  return {
    type: clientConstants.RENDER_IMAGE
  };
};
const handleImageUploadUrl = (data, id, name) => {
  return (dispatch) => {
    clientDataApi
      .UpdateImageUrlRequest(data, id, name)
      .then((response) => {
        dispatch(postImageSuccess(response));
        dispatch(renderImageSuccess());
      })
      .catch((error) => {
        dispatch(postImageError(error));
      });
  };
};

const uploadImageSuccess = (response) => {
  return {
    type: clientConstants.UPLOAD_IMAGE_SUCCESS,
    uploadImageData: response.data.info.docUrl
      ? response.data.info.docUrl
      : response.data.info.fileData.fileUrl,
    uploadImageLoading: false,
  };
};

const uploadImageforProfile = (file, origin, id) => {
  return (dispatch, getState) => {
    clientDataApi
      .uploadImageforProfileRequest(file, origin)
      .then((response) => {
        dispatch(handleImageUploadUrl(response.data.info.docUrl, id, "test"));
      })
      .catch((error) => {
        dispatch(uploadImageError(error));
      });
  };
};


const postImage = ({ fileData, id, notesText, props, functionalityFor, clientData, selectedClient }) => {
  return (dispatch) => {
    if (!functionalityFor) {
      dispatch(getMemoriesloading());
    }
    if (["imageUpload",  "editImage"].includes(functionalityFor)) {
      dispatch(updateNotesLoading());
    } 
    clientDataApi
      .postImageRequest(fileData)
      .then((response) => {
        let awsurl = response.data.info?.fileData?.fileUrl;
        // To edit an image Just changing the url
        if (functionalityFor === "editImage") {
          let idImg = id.split("@");
          let ide = idImg[0];
          let parser = new DOMParser();
          let doc = parser.parseFromString(notesText, "text/html");
          let imgElements = doc.querySelectorAll(`img[alt="${ide}"]`);
          imgElements.forEach((img) => {
            img.setAttribute("src", awsurl);
            img.setAttribute("height", "25px");
            img.setAttribute("width", "25px");
          });
          notesText = doc.body.innerHTML;
          let data = {
            notes: notesText,
            notesFor: props?.source === "CALANDER" ? "CALANDAR" : "TRANSACTION",
            ...(props?.detailsData ? { ...props?.detailsData } : {}),
          };
          if (props?.source === "CALANDER") {
            data.startTimestamp = props.startTimestamp
          }
          dispatch(landingPageAction.saveNotesInCalendar(data, props, true));
        } else if (functionalityFor === "imageUpload") {
          dispatch(Update_Notes_And_Image({ awsurl, id, notesText, props, clientData, selectedClient, functionalityFor }));
        } else {
          dispatch(handleImageUploadUrl(awsurl, id.id, "test"));
        }
      })
      .catch((error) => {
        dispatch(postImageError(error));
        if (["imageUpload",  "editImage"].includes(functionalityFor)) {
          dispatch(landingPageAction.setNotesPanelLoading(false));
        }
        dispatch(updateNotesError(error));
      });
  };
};

// Updating the Notes when we upload an Image
const Update_Notes_And_Image = ({awsurl, id, notesText, props,clientData,selectedClient,functionalityFor}) => (dispatch) => {
  const ide = Math.floor(10000 + Math.random() * 90000);
  const img = `<img alt="${ide}"
    height="25px" width="25px" src="${awsurl}" style="margin-right: 10px;"
    />`;
  const spacingElement = '<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>';
  let data = {
    notes: notesText + spacingElement + img + spacingElement,
    notesFor: (props?.source === "CALANDER") ? "CALANDAR" : "TRANSACTION",
    ...(props?.detailsData ? {...props?.detailsData} : {})
  };
  if(props?.source === "CALANDER"){
    data.startTimestamp=props?.startTimestamp
  }
  if(props?.source === "CLIENT"){
    dispatch(clientAction.updateNotes({notes:data.notes}, clientData, selectedClient,functionalityFor));
  }else{
    dispatch(landingPageAction.saveNotesInCalendar(data, props, true));
  }
};

// Profile Image Url updating in the persondeatils in MongoDB
const uploadImageLoading = () => {
  return {
    type: clientConstants.POST_PROFILE_IMAGE_LOADING,
  };
};
const uploadImageError = (error) => {
  return {
    type: clientConstants.POST_PROFILE_IMAGE_FAILURE,
    postProfileImageError: error,
  };
};

const postprofileImageSuccess = (r) => {
  return {
    type: clientConstants.POST_PROFILE_IMAGE_SUCCESS,
  };
};
// .data.info.fileData.fileUrl,
const profileImageUploadUrl =
  (response, id, name, originalData, section, sourcePlace, forProfileClient) => (dispatch) => {
    dispatch(uploadImageLoading());
    return clientDataApi
      .ProfileImageUrlRequest(
        response.data.info.fileData.fileUrl,
        id,
        name,
        section,
        sourcePlace
      )
      .then((response) => {
        dispatch(postprofileImageSuccess(response));
        if (section === "ACCOUNT") {
          dispatch(accountHandle.getUserDetails());
        } else if (section === "CLIENT") {
          forProfileClient(id, originalData?.fullName || "");
        }
        message.success("Updated Successfully!", 2);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while uploading profile image url. Please try again later!"
        );
        dispatch(postImageError(error));
      });
  };

// uploadig Image on the amazon S3 bucket and from there getting the Image Url as a response, and then dispatching that url  to update the client.
const uploadImage = (values, clientId, clientDetails, section, sourcePlace, forProfileClient) => {
  return (dispatch, getState) => {
    clientDataApi
      .uploadImageRequest(values)
      .then((response) => {
        // dispatch(postprofileImageSuccess(response));
        dispatch(
          profileImageUploadUrl(
            response,
            clientId,
            "test",
            clientDetails,
            section,
            sourcePlace,
            forProfileClient
          )
        );
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while uploading image. Please try again later!"
        );
        dispatch(uploadImageError(error));
      });
  };
};

const savePdfDocumentForClient = (url, id, documentaryId) => {
  return (dispatch, getState) => {
    clientDataApi
      .savePdfDocumentForClientApi(url, id, documentaryId)
      .then((response) => {})
      .catch((error) => {});
  };
};

const uploadPdfDocument = (values, id, data, section, documentaryId) => {
  return (dispatch, getState) => {
    clientDataApi
      .uploadPdfRequest(values)
      .then((response) => {
        dispatch(
          savePdfDocumentForClient(
            response.data.info.fileData?.fileUrl,
            id,
            documentaryId
          )
        );
      })
      .catch((error) => {});
  };
};

// DRAFT DOCUMENTS API Calling

const getDraftDocumentsLoading = (error) => {
  return {
    type: clientConstants.DRAFT_DOCUMENTS_LOADING,
  };
};
const getDraftDocumentsFailure = (error) => {
  return {
    type: clientConstants.DRAFT_DOCUMENTS_FAILURE,
    getDraftFailure: error,
  };
};

const getDraftDocumentsSucess = (response) => {
  return {
    type: clientConstants.DRAFT_DOCUMENTS_SUCCESS,
    getDraftDocumentData: response,
    getDraftLoading: false,
  };
};

const getDraftDocumentData = ({data, unmounted, source}) => (dispatch) => {
  dispatch(getDraftDocumentsLoading());
  return clientDataApi
    .draftDocumentsApi({data, source})
    .then((response) => {
      if(!unmounted.value){
        dispatch(getDraftDocumentsSucess(response.data.info));
      }
    })
    .catch((error) => {
      if(!unmounted.value){
        dispatch(getDraftDocumentsFailure(error));
      }
    });
};

// DRAFT DOCUMENT url shorthand

const draftDocumentUrlShortningError = (error) => {
  return {
    type: clientConstants.URL_SHORTNING_DRAFT_DOCUMENT_FAILURE,
    draftDocumentError: error,
    draftDocumentLoading: false,
  };
};

const draftDocumentUrlShortningSuccess = (response) => {
  return {
    type: clientConstants.URL_SHORTNING_DRAFT_DOCUMENT_SUCCESS,
    draftDocumentData: response,
    draftDocumentLoading: false,
  };
};

const draftDocumentUlShortning = (data, currentPath, clientId) => {
  return (dispatch, getState) => {
    clientDataApi
      .draftDocumentUrlShortning(data)
      .then((response) => {
        const tabId = uuidv4();
        if (clientId && currentPath) {
          document.cookie = `${tabId}=${currentPath}; domain=.resure.realestate; path=/;`;
         window.location.href = `${apiURL}/contractDocument/${response?.data?.info?._id}?tabId=${tabId}&reqId=${clientId}`;
        } else if (currentPath) {
          document.cookie = `${tabId}=${currentPath}; domain=.resure.realestate; path=/;`;
          window.location.href = `${apiURL}/contractDocument/${response?.data?.info?._id}?tabId=${tabId}`;
       } else {
        window.location.href = `${apiURL}/contractDocument/${response?.data?.info?._id}`;
     }
        dispatch(draftDocumentUrlShortningSuccess(response?.data?.info?._id));
      })
      .catch((error) => {
        dispatch(draftDocumentUrlShortningError(error));
      });
  };
};

//Filter ClientRequest
const filterClientLoading = () => {
    return {
      type: clientConstants.FILTER_CLIENT_LIST_LOADING,
    };
  };
  const filterClientError = (error) => {
    return {
      type: clientConstants.FILTER_CLIENT_LIST_FAILURE,
      getClientError: error,
    };
  };

  const filterClientSuccess = (response) => {
    return {
      type: clientConstants.FILTER_CLIENT_LIST_SUCCESS,
      getClientData: response,
    };
  };

  const filterClientList = (query) => (dispatch) => {
    dispatch(filterClientLoading());
    return clientDataApi
      .getFilterClientRequest(query)
      .then((response) => {
        const newData = response?.data?.info;
        dispatch(filterClientSuccess(newData));
      })
      .catch((error) => {
        dispatch(filterClientError(error));
      });
  };

  //Search ClientRequest
const searchClientLoading = () => {
    return {
      type: clientConstants.SEARCH_CLIENT_LIST_LOADING,
    };
  };
  const searchClientError = (error) => {
    return {
      type: clientConstants.SEARCH_CLIENT_LIST_FAILURE,
      getClientError: error,
    };
  };

  // const searchClientSuccess = (response) => {
  //   return {
  //     type: clientConstants.SEARCH_CLIENT_LIST_SUCCESS,
  //     getClientData: response,
  //   };
  // };

  const searchClientList = (query, section, page, limit) => (dispatch) => {
    dispatch(searchClientLoading());
    return clientDataApi
      .getSearchClientRequest(query, section, page, limit)
      .then((response) => {
        const newData = response?.data?.info;
        dispatch(getClientSuccess(newData));
      })
      .catch((error) => {
        dispatch(searchClientError(error));
      });
  };
// Calendar url shorthand

const calendarUrlShortningError = (error) => {
  return {
    type: clientConstants.URL_SHORTNING_CALENDAR_FAILURE,
    calendarError: error,
    calendarLoading: false,
  };
};

const calendarUrlShortningSuccess = (response) => {
  return {
    type: clientConstants.URL_SHORTNING_CALENDAR_SUCCESS,
    calendarData: response,
    calendarLoading: false,
  };
};

const calendarUlShortning = (data) => {
  return (dispatch, getState) => {
    clientDataApi
      .draftDocumentUrlShortning(data)
      .then((response) => {
        window.location = `${apiURL}/contractDocument/${response.data.info._id}`;
        dispatch(calendarUrlShortningSuccess(response.data.info._id));
      })
      .catch((error) => {
        dispatch(calendarUrlShortningError(error));
      });
  };
};
const reSet = () => ({
  type: clientConstants.PERSONALDATA_RESET,
});

const reSetName = () => ({
  type: clientConstants.CLIENT_NAME_RESET,
});
const reSetAddress = () => ({
  type: clientConstants.CLIENT_ADDRESS_RESET,
});
const reSetPhone = () => ({
  type: clientConstants.PHONE_RESET,
});
const reSetEmail = () => ({
  type: clientConstants.EMAIL_RESET,
});
const reSetTitle = () => ({
  type: clientConstants.TITLE_RESET,
});
const reSetNotes = () => ({
  type: clientConstants.NOTES_RESET,
});
const reSetBirthDay = () => ({
  type: clientConstants.BIRTHDAY_RESET,
});
const reSetProfileImage = () => ({
  type: clientConstants.PROFILE_IMAGE_RESET,
});
const reSetPersonDocs = () => ({
  type: clientConstants.PERSON_DOCS_RESET,
});

export const clientAction = {
  handleDraftOpen,
  handleCurrentOpen,
  handlePastOpen,
  getClientList,
  postImage,
  getPerson,
  getCurrentClient,
  getPastClient,
  reSet,
  reSetName,
  reSetAddress,
  reSetPhone,
  reSetEmail,
  reSetTitle,
  reSetNotes,
  reSetBirthDay,
  reSetProfileImage,
  reSetPersonDocs,
  getMemories,
  getDocumentryDetails,
  deleteMemories,
  postEvent,
  editEvent,
  deleteEvent,
  updateName,
  updateAddress,
  updatePhone,
  updateEmail,
  updateTitle,
  updateNotes,
  updateBirthday,
  uploadImage,
  getPersonDocs,
  getDraftDocumentData,
  updateNoteApi,
  draftDocumentUlShortning,
  calendarUlShortning,
  uploadImageforProfile,
  uploadPdfDocument,
  profileImageUploadUrl,
  updateRoleHashtags,
  filterClientList,
  searchClientList,
  clearGetClientData
};
