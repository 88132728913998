/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import houseImage from "../../Assets/imagePlaceholder.webp";
import RtdRow from "./RtdRow";
import { Avatar,Spin, Modal, Tooltip, Typography,  Image} from "antd";
import {   FolderFilled, FolderOpenOutlined, UserOutlined, FileImageOutlined, FileTextOutlined } from "@ant-design/icons";
import { BsInfoLg } from "react-icons/bs";
import { FaRegFilePdf } from "react-icons/fa";
import style from "../style/rtd.module.css";
import moment from "moment";
import { documentAction } from "../../DocumentSection/state/actions";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import TransactionEmail from "./TransactionEmail";
import { transactionLaneDataHandle } from "../state/actions";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import AgentDetailsModal from "./AgentDetailsModal";
const { Text } = Typography;

function RtdWindow({ tags, client, section }) {
  const { getTransactionLaneData, getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane);
  const isSmallScreen = useMediaQuery({maxWidth: 550});
  const isMediumScreen = useMediaQuery({minWidth: 551, maxWidth: 850});
  const [showmodal, setshowModal] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [userState, setUserState] = useState({
    showDetailModal: false,
    agentData: {},
    source: "",
  });

  const handleShowAgentDetail = (item, source) => {
    if (source === "SELLER_AGENT") {
      setUserState({
        showDetailModal: true,
        agentData: item?.sellerAgentId || item?.newTransactionData,
        source,
      });
    } else {
      setUserState({
        showDetailModal: true,
        agentData: item?.buyerAgentId || item?.newTransactionData,
        source,
      });
    }
  };

  let sellerAgentName = getIndividualTransactionData?.sellerAgentId?.fullName || getIndividualTransactionData?.newTransactionData?.["SellerBrokerName"];
  const buyerAgentName = getIndividualTransactionData?.buyerAgentId?.fullName || getIndividualTransactionData?.newTransactionData?.["BuyerBrokerName"];

  const handleNeedToKnowOpen = (ele) => {
    history.push({pathname: location?.pathname?.replace("Dashboard", "Dashboard/needToKnow")});
  };
  return (
    <>
       {/* Agent Details modal  */}
      {userState?.showDetailModal && <AgentDetailsModal userState={userState} setUserState={setUserState} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} />}
      <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        textAlign: "center",
        marginTop: "-60px",
        position: "relative",
      }}
    >
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          left: "25%",
          right: "25%",
          top:"6%"
        }}
      >
        {getIndividualTransactionData?.address ? (
          <>
            <div className={style.box}>
              <div className={style.imgBox}>
                <Image preview style={{ height: "60px", width: "70px", objectFit:"cover" }} src={typeof getIndividualTransactionData?.images[0]?.imageUrl == "string" && getIndividualTransactionData?.images[0]?.imageUrl !== "" ? getIndividualTransactionData?.images[0]?.imageUrl : houseImage} alt="home" />{" "}
              </div>
              <div className={style.addressBox}>
                <p>{getIndividualTransactionData?.address}</p>
                {getIndividualTransactionData?.listingId ? (
                  <p>
                    MLS Listing Id: <>{getIndividualTransactionData?.listingId}</>
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {getIndividualTransactionData?.transactionId ? (
          <>
            <div style={{ display: "flex", justifyContent: "center", cursor: "pointer", alignItems: "center", textAlign: "center" }}>
              <span onClick={handleNeedToKnowOpen} style={{ fontWeight: "bold", fontSize: "15px", textDecoration: "underline", color: "#4747FF" }}>
                Transaction Email Address{" "}
              </span>
              <span style={{ fontSize: "25px", marginLeft: "5px" }}>
                {" "}
                <Tooltip title={'Any email sent to the address below will be copied into the transaction report. Agents and people listed on the "Need to Know" list will receive a copy of the message.'} placement="right">
                  <BsInfoLg />
                </Tooltip>{" "}
              </span>
            </div>
            <TransactionEmail selectedData={getIndividualTransactionData} />
          </>
        ) : (
          ""
        )}
      </div>
      {/* buyer seller divs  */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          //   marginTop: "6%",
          marginTop: "9vh",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {getIndividualTransactionData && getIndividualTransactionData?.sellerAgentId && getIndividualTransactionData?.sellerAgentId?.profileImg && getIndividualTransactionData?.sellerAgentId?.profileImg?.original ? (
            <Avatar src={getIndividualTransactionData && getIndividualTransactionData.sellerAgentId && getIndividualTransactionData.sellerAgentId.profileImg && getIndividualTransactionData.sellerAgentId.profileImg.original ? getIndividualTransactionData.sellerAgentId.profileImg.original : null} onClick={() => handleShowAgentDetail(getIndividualTransactionData, "SELLER_AGENT")}   style={{ cursor: "pointer", margin: "auto" }} size={64} icon={<UserOutlined style={{ fontSize: "34px" }} />} />
          ) : (
            <Avatar onClick={() => handleShowAgentDetail(getIndividualTransactionData, "SELLER_AGENT")}  style={{ cursor: "pointer", margin: "auto", fontSize: "27px", border: "1px solid #085191", background: "#085191" }} size={64}>
              {sellerAgentName ? (
                sellerAgentName
                  ?.split(" ")
                  .map((namePart) => namePart.charAt(0).toUpperCase())
                  .join("")
                  .slice(0, 2)
              ) : (
                <UserOutlined style={{ fontSize: "34px" }} />
              )}
            </Avatar>
          )}
          <div style={{ maxWidth: "130px", margin: "auto" }}>
            <Text ellipsis style={{ fontWeight: "bold", color: "grey", fontSize: "19px" }}>
              {sellerAgentName}
            </Text>
          </div>
          <div
            style={{
              background: "#2976be",
              width: "140px",
              height: "33px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "14px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          >
            <p
              style={{
                textAlign: "center",
                marginTop: "12%",
                fontWeight: "bold",
                color: "white",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              <div>Seller Agent</div>
            </p>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          {getIndividualTransactionData && getIndividualTransactionData?.buyerAgentId && getIndividualTransactionData?.buyerAgentId?.profileImg && getIndividualTransactionData?.buyerAgentId?.profileImg?.original ? (
            <Avatar src={getIndividualTransactionData && getIndividualTransactionData?.buyerAgentId && getIndividualTransactionData?.buyerAgentId?.profileImg && getIndividualTransactionData?.buyerAgentId?.profileImg?.original ? getIndividualTransactionData?.buyerAgentId?.profileImg?.original : null} onClick={() => handleShowAgentDetail(getIndividualTransactionData, "BUYER_AGENT")}   style={{ cursor: "pointer", margin: "auto" }} size={64} icon={<UserOutlined style={{ fontSize: "34px" }} />} />
          ) : (
            <Avatar onClick={() => handleShowAgentDetail(getIndividualTransactionData, "BUYER_AGENT")} style={{ cursor: "pointer", margin: "auto", fontSize: "27px", border: "1px solid #085191", background: "#085191" }} size={64}>
              {buyerAgentName ? (
                buyerAgentName
                  ?.split(" ")
                  .map((namePart) => namePart.charAt(0).toUpperCase())
                  .join("")
                  .slice(0, 2)
              ) : (
                <UserOutlined style={{ fontSize: "34px" }} />
              )}
            </Avatar>
          )}
          <div style={{ maxWidth: "130px", margin: "auto" }}>
            <Text ellipsis style={{ fontWeight: "bold", color: "grey", fontSize: "19px" }}>
              {buyerAgentName}
            </Text>
          </div>
          <div
            style={{
              background: "#ffce51",
              width: "140px",
              height: "33px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "14px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
          >
            <p
              style={{
                textAlign: "center",
                marginTop: "12%",
                fontWeight: "bold",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              <div>Buyer Agent</div>
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "calc(80vh - 12.2rem)",
        }}
        className={style.invisibleScroll}
      >
        <div
          style={{
            flex: 1,
            overflowY: "auto",
            overflowX: "hidden",
            paddingRight: "17px", // Adjust based on the width of the scrollbar
            marginRight: "-17px", // Adjust based on the width of the scrollbar
            marginBottom: "5px",
          }}
        >
            <div style={{
              width: "100%",
              borderStyle: "solid",
              minHeight: "100%",
              borderRightWidth: 8,
              borderLeftWidth: 8,
              borderBottomWidth: 0,
              borderTopWidth: 0,
              borderRightColor: "#ffce51",
              borderLeftColor: "#3376a8",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              alignSelf: "center",
            }}>
              {getTransactionLaneData &&
                getTransactionLaneData.map((item, i) => {
                  return <RtdRow {...item} key={item._id} tags={tags} client={client} selectedData={getIndividualTransactionData} section={section}/>
                })
              }
            </div>
        </div>
        {!showmodal && (getIndividualTransactionData?.status === "ACCEPTED" || "SUBMITTED" || tags === "SELLER") ? (
          <button
            style={{
              width: "60%",
              marginTop: "10px",

              margin: "auto",
              height: "25px",
              borderRadius: "15px",
              fontWeight: "500",
              fontSize: "18px",
              alignItems: "center",
              borderColor: "#2976be",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              color: "#096dd9",
            }}
            onClick={() => {
              setshowModal(true)
            }
          }
          >
            <div style={{ marginRight: "10px", color: "#2976be" }}>
              <FolderFilled />
            </div>
            Shared
          </button>
        ) : (
          ""
        )}

      </div>
      {/* Shared docs modal */}
      <SharedModel setshowModal={setshowModal} showmodal={showmodal} section={section} client={client}/>
    </div>
    </>

  );
}

const SharedModel = ({setshowModal, showmodal, section, client}) => {
  const delegate = (section === "DELEGATE");
  const { getIndividualTransactionData, getPropertyDocsData, getPropertyDocsLoading, getPropertyDocsError, getPropertyDocsErrorMsg} = useSelector((state) => state.rtdTransactionLane);
  const { userId } = useSelector((state) => state.account);
  const location = useLocation();
  const dispatch = useDispatch();

  let source = axios.CancelToken.source();
  let unmounted = {
    value: false
  };
  useEffect(()=>{
    if(showmodal){
      const delegateSide = delegate && client;
      dispatch(transactionLaneDataHandle.getPropertyDocs({offerId: getIndividualTransactionData?.offerId, unmounted, source, delegateSide}));

      return () => {
        unmounted.value = true;
        source.cancel("Cancelling in cleanup");
      }
    }
  }, [showmodal]);

  const handleOPenDocs = (ele) => {
    if (ele?.fileUrl) {
      window.open(ele?.fileUrl);
    }
  };

  const handlePropertyDocs = (ele) => {
    let buildData = {
      builtForId: userId,
      key: userId,
      builtForSection: "DOCUMENT",
      signable: true,
      openFor: "SELLER_AGENT",
      contractId: ele.contractId,
      documentId: ele?.documentId,
      token: localStorage.getItem("userAuth"),
    };
    dispatch(documentAction.draftDocumentURl(buildData, location?.pathname));
  };

  return <Modal visible={showmodal} onOk={() => {}} footer={false} width={"800px"} onCancel={() => setshowModal(false)}>
    <div style={{ height: "60vh", overflowY: "auto" }}>
      <div
        style={{
          fontWeight: "500",
          fontSize: "20px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          color: "#096dd9",
        }}
      >
        <div
          style={{
            // border: "1px solid black",
            display: "flex",
            border: "none",
            justifyContent: "center",
          }}
        >
          <div style={{ marginRight: "10px", color: "#2976be" }}>
            <FolderOpenOutlined />
          </div>
        </div>
        Shared
      </div>

      <hr />
      <div style={{ height: "50vh", overflowY: "auto" }}>
        {getPropertyDocsLoading ? (
        <div style={{ height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spin size="large" />
      </div>
        ) :
          getPropertyDocsError ? ""
        : (
          <>
            {getPropertyDocsData?.length > 0 ? (
              <table className="licenseTable">
                <thead>
                  <tr>
                  <th> File Name</th>
                    <th>Shared Date</th>
                    <th> Shared By</th>
                  </tr>
                </thead>
                <tbody>
                  {getPropertyDocsData?.map((ele) => (
                    <tr key={ele._id}>
                      <td
                        onClick={() => (ele.fileType === "IMAGE" || ele.fileType === "PDF" ? handleOPenDocs(ele) : handlePropertyDocs(ele))}
                        style={{
                          fontWeight: "bolder",
                          color: "blue",
                          minWidth: "250px",
                          width: "55%",
                          cursor: "pointer",
                        }}
                      >
                        {ele.fileType === "IMAGE" ? <FileImageOutlined style={{ fontSize: "20px", color: "#ecd540", marginRight: "5px" }} /> : ele.fileType === "PDF" ? <FaRegFilePdf style={{ fontSize: "20px", color: "red", marginRight: "5px" }} /> : <FileTextOutlined style={{ fontSize: "20px", color: "#74b3ed", marginRight: "5px" }} />}
                        {ele.offerDocument ? ele.documentName : ele?.fileName || ""}
                      </td>
                      <td
                        style={{
                          color: "black",
                          fontWeight: "500",
                          textAlign: "center",
                          minWidth: "150px",
                          width: "25%",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {moment(ele.sentDateMili).format("MM/DD/YYYY hh:mm A")}
                      </td>
                      <td
                        style={{
                          color: "grey",
                          fontSize: "15px",
                          fontWeight: "400",
                          textAlign: "center",
                          minWidth: "150px",
                          width: "25%",
                        }}
                      >
                        {ele?.sharedBy?.fullName || ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </>
        )}{" "}
      </div>
    </div>
  </Modal>
}

export default RtdWindow;
